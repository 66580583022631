<div class="grid entry-exit">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Entry/ Exit Detail (Trailer Tracking)</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="trailerTrackingVisible" pButton pRipple label="Trailer Tracking" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToTrailerTracking()"></button>
                    <button *ngIf="userType !== 'ROLE_CLIENT'" pButton pRipple label="New" icon="pi pi-plus" class="p-button-secondary mr-2"  (click)="openEntryExitDialog()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->
                    <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                        
                </ng-template>
            </p-toolbar>

            <p-table #dt [value]="entryExitList" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['location.locationName','dateOfEntry','fleet.carrier','fleet.unitNumber','fleet.owner','type','entryTime','audit.createdBy.firstName']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span>
                            <p-dropdown *ngIf="userType !== 'ROLE_CLIENT'" [showClear]="true" placeholder="Select Client" [options]="clients" (onChange)="filterFleetsByClient($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                        </span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 70px;"></th>
                        <th pSortableColumn="locations">Locations</th>
                        <th pSortableColumn="dateOfEntry">Date of Entry</th>
                        <th pSortableColumn="carrier">Carrier</th>
                        <th pSortableColumn="unitNumber">Unit #</th>
                        <th pSortableColumn="owner">Trailer/Container Owner</th>
                        <th pSortableColumn="typeOfEntry">Type</th>
                        <th pSortableColumn="trailer">Trailer/Container#</th>
                        <th pSortableColumn="entryTime">Entry Time</th>
                        <th pSortableColumn="exitTime">Exit Time</th>
                        <th pSortableColumn="reportBy">Reported By</th>
                        <!-- <th pSortableColumn="remarks">Remarks <p-sortIcon field="remarks"></p-sortIcon></th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entry>
                    <tr>
                        <td>
                            <button pButton pRipple icon="pi pi-book" pTooltip="View Notes" [ngClass]="entry.type == 'ENTRY' ? 'p-button-success' : 'p-button-danger' " class="p-button-rounded mt-2" (click)="viewNotes(entry.notes)"></button>
                            <button *ngIf="userType !== 'ROLE_CLIENT' && hideButtonIfGuard === false" pButton pRipple icon="pi pi-trash" pTooltip="Delete" class="p-button-rounded p-button-danger mt-2" (click)="deleteEntryExit(entry.location.clientId,entry.guardEntryExitId)"></button>
                            <button *ngIf="entry.billOfLandingImage" pButton pRipple
                                icon="pi pi-images" pTooltip="View BOL images"
                                class="p-button-rounded p-button-secondary mt-2"
                                (click)="viewBol(entry)"></button>
                        </td>
                        <td>
                            <span class="p-column-title">Locations</span>
                            {{entry?.location?.locationName}}
                        </td>
                        <td>
                            <span class="p-column-title">Date of Entry</span>
                            {{ entry?.dateOfEntry }}
                        </td>
                        <td>
                            <span class="p-column-title">Carrier</span>
                            {{entry?.fleet?.carrier}}
                        </td>
                        <td>
                            <span class="p-column-title">Unit #</span>
                            {{entry?.fleet?.unitNumber}}
                        </td>
                        <td>
                            <span class="p-column-title">Trailer/Container Owner</span>
                            {{entry?.fleet?.owner ? entry?.fleet?.owner : '-'}}
                        </td>
                        <td>
                            <span class="p-column-title">Type</span>
                            {{entry?.type}}
                        </td>
                        <td>
                            <span class="p-column-title">Trailer/Container#</span>
                            {{entry?.fleet?.unitNumber}}
                        </td>
                        
                        <td>
                            <span class="p-column-title">Entry Time</span>
                            {{entry?.type == 'ENTRY' ? entry?.entryTime : '-'}}
                        </td>
                        <td>
                            <span class="p-column-title">Exit Time</span>
                            {{entry?.type == 'EXIT' ? entry?.entryTime : '-'}}
                        </td>
                        <td>
                            <span class="p-column-title">Reported By</span>
                            {{entry?.audit?.createdBy?.firstName}} {{entry?.audit?.createdBy?.lastName}}
                        </td>
                        <!-- <td style="width:14%; min-width: 10rem;"><span class="p-column-title">Remarks</span>
                            {{product.remarks}}
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">No entry exit record found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
        </div>

        <p-dialog [(visible)]="deleteProductDialog" header="Notes" [modal]="true" [style]="{width:'450px'}">
            <div class="flex flex-column md:flex-row align-items-center p-3 w-full">
                <div class="flex-1 md:text-left">
                    <div class="flex align-items-center mt-3">
                        <p>{{modalNotes}}</p>
                    </div>
                </div>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-check" class="p-button-success" label="Ok" (click)="hideDialog()"></button>
            </ng-template>
        </p-dialog>

        
        <p-dialog [(visible)]="entryExitDialog" [style]="{width: '60%'}" header="Create Entry/ Exit Detail (Trailer Tracking)" [modal]="true" (onHide)="hideDialog()" class="p-fluid">
            <ng-template pTemplate="content">
                <form [formGroup]="entryExitForm">
                    <div class="p-fluid p-formgrid grid entry_exit_grid">
                        <div class="field col-12 md:col-6">
                            <label htmlFor="client">Select Client<span class="text-danger">*</span></label>
                            <p-dropdown placeholder="Select Client" formControlName="clientId" [options]="clientList" (onChange)="onClientSelect($event)" optionLabel="clientName" optionValue="clientId" appendTo="body"></p-dropdown>
                            <small class="text-danger" *ngIf="(entryExitForm.controls.clientId.touched &&
                            entryExitForm.controls.clientId.invalid && entrySubmitted) || isTrailerClicked ">Client is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="type">Type<span class="text-danger">*</span></label>
                            <p-dropdown [options]="type" placeholder="Select Type" formControlName="type" optionLabel="name" optionValue="code" (onChange)="onExitSelect($event)" appendTo="body"></p-dropdown>
                            <small class="text-danger" *ngIf="entryExitForm.controls.type.touched &&
                            entryExitForm.controls.type.invalid && entrySubmitted">Type is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="client">Pro Number</label>
                            <input pInputText id="proNumber" type="text" placeholder="Type Pro Number" formControlName="proNumber" />
                            <small class="text-danger" *ngIf="entryExitForm.controls.proNumber.touched &&
                            entryExitForm.controls.proNumber.invalid && entrySubmitted">Client is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Location<span class="text-danger">*</span></label>
                            <p-dropdown [options]="clientsLocationsList" formControlName="locationId" (onChange)="forLocationId($event)"  placeholder="Select Pickup Location" optionLabel="locationName" optionValue="locationId" appendTo="body"></p-dropdown>
                            <small class="text-danger" *ngIf="entryExitForm.controls.locationId.touched &&
                            entryExitForm.controls.locationId.invalid && entrySubmitted">Location is required.</small>
                        </div>
                        
                        
                        
                       
                        
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Trailer#<span class="text-danger">*</span><p-tag *ngIf="!newTrailer" class="tag-hover cursor-pointer" severity="success" (click)="showNewTrailerFields()" value="New Asset" icon="pi pi-plus"></p-tag><p-tag *ngIf="newTrailer" class="tag-hover cursor-pointer" severity="warning" (click)="showNewTrailerFields()" value="Back" icon="pi pi-directions-alt"></p-tag></label>
                            <p-autoComplete *ngIf="!newTrailer" placeholder="Type Trailer Number" formControlName="trailer" [suggestions]="fleetList" (onSelect)="onFleetSelect($event)" dataKey="fleetId" field="fleetAndHotTrailer" (completeMethod)="getFleetList($event)" [dropdown]="true" (onClear)="clearFilter($event)">
                                <ng-template let-fleet pTemplate="item">
                                    <div>{{fleet.fleetAndHotTrailer}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <input *ngIf="newTrailer" pInputText id="fleetId" type="text" formControlName="newFleet" />
                            <button *ngIf="newTrailer" pButton pRipple label="Add" icon="pi pi-primary" class="p-button-text" style="width: auto; float: right;" (click)="addTrailer()"></button> 
                            <small class="text-danger" *ngIf="entryExitForm.controls.fleetId.touched &&
                            entryExitForm.controls.fleetId.invalid && entrySubmitted && (entryExitForm.value.newFleet==null || entryExitForm.value.newFleet=='')">Trailer is required.</small>
                            <small class="text-danger" *ngIf="entryExitForm.controls.fleetId.touched &&
                            entryExitForm.controls.fleetId.invalid && entrySubmitted && (entryExitForm.value.newFleet!=null && entryExitForm.value.newFleet!='')">Please add the new trailer by clicking on the add button before submitting</small>
                            <!-- <label for="fleet">Trailer/Container#<span class="text-danger">*</span></label>
                            <p-dropdown [options]="fleetList" placeholder="Select Trailer/Container" formControlName="fleetId" optionLabel="fleetAndHotTrailer" optionValue="fleetId"></p-dropdown>
                            <small class="ng-dirty ng-invalid" *ngIf="entryExitForm.controls.fleetId.touched &&
                            entryExitForm.controls.fleetId.invalid">Trailer/Container is required.</small> -->
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Spot<span class="text-danger">*</span></label>
                            <p-dropdown [options]="clientsSpotsList" formControlName="spotId" placeholder="Select Spot" optionLabel="spotName" optionValue="spotId" appendTo="body"></p-dropdown>
                            <small class="text-danger" *ngIf="entryExitForm.controls.spotId.touched &&
                            entryExitForm.controls.spotId.invalid && entrySubmitted">Spot is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="type">Carrier<span class="text-danger">*</span><p-tag *ngIf="!newCarrier" class="tag-hover cursor-pointer" severity="success" (click)="showNewCarrier()" value="New Carrier" icon="pi pi-plus"></p-tag><p-tag *ngIf="newCarrier" class="tag-hover cursor-pointer" severity="warning" (click)="showNewCarrier()" value="Back" icon="pi pi-directions-alt"></p-tag></label>
                            <!-- <p-dropdown *ngIf="!newCarrier" [options]="carrierList" placeholder="Select Carrier Name" formControlName="carrier" optionLabel="carrier" optionValue="carrierId" appendTo="body"></p-dropdown> -->
                            <!-- <p-autoComplete *ngIf="!newCarrier" placeholder="Type Carrier Name" formControlName="carrierName" [suggestions]="carrierList" (onSelect)="onCarrierSelect($event)" dataKey="carrierId" field="carrier" (completeMethod)="getCarriers($event)" [dropdown]="true" (onClear)="clearCarrierFilter($event)">
                                <ng-template let-carrier pTemplate="item">
                                    <div>{{carrier.carrier}}</div>
                                </ng-template>
                            </p-autoComplete> -->
                            <p-autoComplete *ngIf="!newCarrier" formControlName="carrier" (completeMethod)="getCarriers($event)" [suggestions]="carrierList"
									dataKey="carrier" [dropdown]="true" (onClear)="clearCarrier()">
									<ng-template>
										<div>{{carrierList}}</div>
									</ng-template>
							</p-autoComplete>
                            <input *ngIf="newCarrier" pInputText id="newCarrier" type="text" formControlName="newCarrier" />
                            <button *ngIf="newCarrier" pButton pRipple label="Add" icon="pi pi-primary" class="p-button-text" style="width: auto; float: right;" (click)="addCarrier()"></button> 
                            <small class="text-danger" *ngIf="entryExitForm.controls.carrier.touched &&
                            entryExitForm.controls.carrier.invalid && entrySubmitted && (entryExitForm.value.newCarrier=='' || entryExitForm.value.newCarrier==null)">Carrier is required.</small>
                            <small class="text-danger" *ngIf="entryExitForm.controls.carrier.touched &&
                            entryExitForm.controls.carrier.invalid && entrySubmitted && (entryExitForm.value.newCarrier!='' && entryExitForm.value.newCarrier!=null)">Please add the new carrier by clicking on the add button before submitting</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Driver<span class="text-danger">*</span></label>
                            <!-- <p-dropdown [options]="drivers" formControlName="driver" placeholder="Select Driver" optionLabel="fullName" optionValue="userId" appendTo="body"></p-dropdown> -->
                            <p-autoComplete placeholder="Select Driver" formControlName="driverName" [suggestions]="drivers" (onSelect)="onDriverSelect($event)" dataKey="userId" field="fullName" (completeMethod)="getDriverList($event)" [dropdown]="true" (onClear)="clearDriverFilter($event)" (onBlur)="saveTypedData()">
                                <ng-template let-drvr pTemplate="item">
                                    <div>{{drvr.fullName}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <small class="text-danger" *ngIf="entryExitForm.controls.driver.touched &&
                            entryExitForm.controls.driver.invalid && entrySubmitted">Driver is required.</small>
                        </div>
                        <!-- <div class="field col-12 md:col-6" *ngIf="newTrailer">
                            <label htmlFor="client">Carrier<span class="text-danger">*</span></label>
                            <p-dropdown [options]="carrierList" formControlName="fleetCarrier" placeholder="Select Carrier Name" optionLabel="carrier" optionValue="carrierId" appendTo="body"></p-dropdown>
                            <small class="text-danger" *ngIf="entryExitForm.controls.proNumber.touched &&
                            entryExitForm.controls.proNumber.invalid ">Carrier is required.</small>
                        </div> -->
                        <!-- <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Trailer Number<span class="text-danger">*</span></label>
                            <input pInputText id="fleetId" type="text" formControlName="fleetId" />
                            <small class="text-danger" *ngIf="entryExitForm.controls.fleetId.touched &&
                            entryExitForm.controls.fleetId.invalid">Trailer is required.</small>
                        </div> -->
                        <div class="field col-12 md:col-6">
                            <label for="type">Sub<span class="text-danger">*</span></label>
                            <input pInputText id="sub" type="text" placeholder="Type Sub" formControlName="sub" />
                            <small class="text-danger" *ngIf="entryExitForm.controls.sub.touched &&
                            entryExitForm.controls.sub.invalid && entrySubmitted">Sub is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="notes">Bill of Lading</label>
                            <div class="flex">
                                <input pInputText id="billOfLandingType" type="text" placeholder="Type Bill Lading" formControlName="billOfLandingType" style="width: 40%" />
                                <div class="file-upload" style="width: 60%">
                                    <input type="file" id="file" class="file-input" (change)="onFileSelected($event)" accept="image/*">
                                    <div class="file-display">
                                      <!-- <img src="placeholder-icon.png" alt="" class="file-icon"> -->
                                      <span class="file-text">{{ fileText }}</span>
                                    </div>
                                    <label for="file" class="file-label">Upload Image</label>
                                  </div>
                                <!-- <input type="file" (change)="onFileSelected($event)" accept="image/*"> -->
                            </div>
                            <small class="text-danger" *ngIf="(entryExitForm.controls.billOfLandingType.touched || entryExitForm.controls.billOfLandingImage.touched) &&
                            (entryExitForm.controls.billOfLandingType.invalid || entryExitForm.controls.billOfLandingImage.invalid) && entrySubmitted">Bill of Lading is required.</small>
                        </div>

                        <div class="field col-12 md:col-6">
                            <label for="type">Load Status<span class="text-danger">*</span></label>
                            <!-- <input pInputText id="loadStatus" type="text" placeholder="Type Load Status" formControlName="loadStatus" /> -->
                            <p-dropdown [options]="loadStatusList" formControlName="loadStatus" placeholder="Select Load Status" optionLabel="loadName" optionValue="loadName" appendTo="body"></p-dropdown>
                            <small class="text-danger" *ngIf="entryExitForm.controls.loadStatus.touched &&
                            entryExitForm.controls.loadStatus.invalid && entrySubmitted">Load Status is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Suppliers<span class="text-danger">*</span><p-tag *ngIf="!newSupplier" class="tag-hover cursor-pointer" severity="success" (click)="showNewSupplier()" value="New Supplier" icon="pi pi-plus"></p-tag><p-tag *ngIf="newSupplier" class="tag-hover cursor-pointer" severity="warning" (click)="showNewSupplier()" value="Back" icon="pi pi-directions-alt"></p-tag></label>
                            <!-- <p-dropdown *ngIf="!newSupplier" [options]="supplierList" formControlName="supplier" placeholder="Select Suppliers" optionLabel="supplier" optionValue="supplierId" appendTo="body"></p-dropdown> -->
                            <p-autoComplete *ngIf="!newSupplier" placeholder="Type Supplier" formControlName="supplierName" [suggestions]="supplierList" (onSelect)="onSupplierSelect($event)" dataKey="supplierId" field="supplier" (completeMethod)="getSuppliers($event)" [dropdown]="true" (onClear)="clearSupplierFilter($event)">
                                <ng-template let-supplier pTemplate="item">
                                    <div>{{supplier.supplier}}</div>
                                </ng-template>
                            </p-autoComplete>
                            <input *ngIf="newSupplier" pInputText id="newSupplier" type="text" formControlName="newSupplier" />
                            <button *ngIf="newSupplier" pButton pRipple label="Add" icon="pi pi-primary" class="p-button-text" style="width: auto; float: right;" (click)="addSupplier()"></button> 
                            <small class="text-danger" *ngIf="entryExitForm.controls.supplier.touched &&
                            entryExitForm.controls.supplier.invalid && entrySubmitted && (entryExitForm.value.newSupplier==null || entryExitForm.value.newSupplier=='')">Supplier is required.</small>
                            <small class="text-danger" *ngIf="entryExitForm.controls.supplier.touched &&
                            entryExitForm.controls.supplier.invalid && entrySubmitted && (entryExitForm.value.newSupplier!=null && entryExitForm.value.newSupplier!='')">Please add the new supplier by clicking on the add button before submitting</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="type">Sequence Number<span class="text-danger">*</span></label>
                            <input pInputText id="sequenceNumber" placeholder="Type Sequence Number" type="text" formControlName="sequenceNumber" />
                            <small class="text-danger" *ngIf="entryExitForm.controls.sequenceNumber.touched &&
                            entryExitForm.controls.sequenceNumber.invalid && entrySubmitted">Sequence Number is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Date of Pickup<span class="text-danger">*</span></label>
                            <p-calendar [showIcon]="true" formControlName="dateOfPickup" appendTo="body" dateFormat="mm/dd/yy"></p-calendar>
                            <small class="text-danger" *ngIf="entryExitForm.controls.dateOfPickup.touched &&
                            entryExitForm.controls.dateOfPickup.invalid && entrySubmitted">Date of Pickup is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="notes">Tractor Number<span class="text-danger">*</span></label>
                            <input pInputText id="tractorNumber" placeholder="Type Tractor Number" type="text" formControlName="tractorNumber" />
                            <small class="text-danger" *ngIf="entryExitForm.controls.tractorNumber.touched &&
                            entryExitForm.controls.tractorNumber.invalid && entrySubmitted">Tractor Number is required.</small>
                        </div>
                     
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Due at Plant</label>
                            <!-- <input pInputText id="dueOfPlant" placeholder="Type Due at Plant" type="text" formControlName="dueOfPlant" /> -->
                            <p-calendar [showIcon]="true" formControlName="dueOfPlant" appendTo="body" dateFormat="mm/dd/yy"></p-calendar>
                            <small class="text-danger" *ngIf="entryExitForm.controls.dueOfPlant.touched &&
                            entryExitForm.controls.dueOfPlant.invalid && entrySubmitted">Due at Plant is required.</small>
                        </div>
                        <div class="field col-12 md:col-6">
                            <label for="type">Updated by<span class="text-danger">*</span></label>
                            <p-dropdown [options]="updatedByList" placeholder="Updated by" formControlName="userName" optionLabel="fullName" optionValue="userId" appendTo="body"></p-dropdown>
                            
                        </div>
                        <div class="field col-12 md:col-6">
                            <label htmlFor="pickupLocation">Date of Arrival<span class="text-danger">*</span></label>
                            <p-calendar [showIcon]="true" formControlName="dateOfArrival" appendTo="body" dateFormat="mm/dd/yy"></p-calendar>
                            <small class="text-danger" *ngIf="entryExitForm.controls.dateOfArrival.touched &&
                            entryExitForm.controls.dateOfArrival.invalid && entrySubmitted">Date of Arrival is required.</small>
                        </div>
                       
                        <div class="field col-12 md:col-6">
                            <label for="notes">Notes</label>
                            <textarea pInputTextarea autoResize formControlName="notes" rows="3" cols="30"></textarea>
                        </div>
                       
                    </div>
                   
                      
                      
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="createEntryExit()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>


<p-dialog [(visible)]="deleteEntryExitDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
	<div class="flex align-items-center justify-content-start">
		<i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
		<span *ngIf="entryExitId">Are you sure you want to delete this entry exit record ?</span>
	</div>
	<ng-template pTemplate="footer">
		<button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteEntryExitDialog = false"></button>
		<button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
	</ng-template>
</p-dialog>

<p-dialog [(visible)]="bolDialog" header="BoL Image" [modal]="true" [style]="{width:'700px',height: '700px'}">

    <ng-template pTemplate="content">
        <div class="field">
            <label>Bill of Lading Type: </label>
            <span>{{ selectedBolType }}</span>
        </div>
        <p-image [src]="selectedBol" alt="Image" width="100%" height="400px"></p-image>
        
    </ng-template>
    <ng-template pTemplate="footer">
        
        <!-- <button pButton pRipple label="Download" class="p-button-success"></button> -->
        <!-- <a [href]="selectedBol" download="downloaded-image.jpg" target="_blank">Download Image</a> -->
    </ng-template>
</p-dialog>
