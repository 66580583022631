import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageLocationsService {

  constructor(private http: HttpClient) { }

  viewLocations(query:IpagedQuery,clientId): Observable<any> {
    const params = new HttpParams({
      fromObject:{
        isActive:query.isActive.valueOf(),
        page:query.page.toString(),
        size:query.size.toString(),
      }
    })
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations`,{params});
  }

  createEntryExit(locationId,clientId,obj):Observable<any>{
    // const formData = new FormData();
    // formData.append('billOfLandingImage', obj.billOfLandingImage);
    // formData.append('billOfLandingType', obj.billOfLandingType);
    // formData.append('carrier', obj.carrier);
    // formData.append('clientId', obj.clientId);
    // formData.append('dateOfArrival', obj.dateOfArrival);
    // formData.append('dateOfPickup', obj.dateOfPickup);
    // formData.append('driver', obj.driver);
    // formData.append('dueOfPlant', obj.dueOfPlant);
    // formData.append('fleetId', obj.fleetId);
    // formData.append('loadStatus', obj.loadStatus);
    // formData.append('locationId', obj.locationId);
    // formData.append('proNumber', obj.proNumber);
    // formData.append('sequenceNumber', obj.sequenceNumber);
    // formData.append('spotId', obj.spotId);
    // formData.append('sub', obj.sub);
    // formData.append('supplier', obj.supplier);
    // formData.append('tractorNumber', obj.tractorNumber);
    // formData.append('type', obj.type);
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}/fleetEntryExitNew`,obj);
  }

  deleteLocation(clientId,locationId): Observable<any> {
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}`);
  }

  makeDefault(clientId,locationId): Observable<any> {
    return this.http.patch(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/clients/${clientId}/locations/${locationId}/makeDefault`,null);
  }
}
