import { Component, OnInit, OnDestroy, ViewChild, ViewChildren, QueryList ,ElementRef, AfterViewInit, HostListener } from '@angular/core';
import { ConfirmationService, FilterService, MenuItem, MessageService ,FilterMatchMode,SelectItem} from 'primeng/api';
import { Router } from '@angular/router';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { Subscription } from 'rxjs';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageFleetsService } from './manage-fleets.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { Paginator } from 'primeng/paginator';
import { Checkbox } from 'primeng/checkbox';
import { OverlayPanel } from 'primeng/overlaypanel';
import { DataService } from 'src/app/service/data.service';
// import { ManageTrailerTrackingServiceService } from '../manage-trailer-tracking/manage-trailer-tracking-service.service';


@Component({
  selector: 'app-manage-fleets',
  templateUrl: './manage-fleets.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss','./manage-fleets.component.scss']
})
export class ManageFleetsComponent implements OnInit, OnDestroy, AfterViewInit {
  
  clientList = [];
  cols: any[];
  breadcrumbItems: MenuItem[];
  deleteClientDialog: boolean;
  loading: boolean;
  query:IpagedQuery;
  clientQuery:IpagedQuery;
  totalRecords: any;
  subscription = new Subscription();
  statuses: any[];
  fleetList = [];
  assignTrailerForm: FormGroup;
  assignFleetModal = false;
  filterByClient: any;
  userRoles: any;
  userType: string;
  accessToken: string;
  tallyObj: any;
  dropdownStatus: { name: string; code: boolean; }[];
  searchboxValue:string;
  unitNumber:string;
  i:number;// 
  j:number;
  unitNumberList = [];
  unitNumberListPopup=[];
  checkboxDisplay = 'none';
  assignUnitNumberModal = false;
  assignUnitNumberForm: FormGroup;
  buttonDisable = false;
  cancelButtonDisable = true;
  editRefreshButtonVisibility = true;
  event:any;
  mergeSpotList = [];
  selectedSpot:string;
  label:string;
  items: MenuItem[];
  selectedOption: string="";
  unitNumber1:string;
  unitNumber2:string;
  spotselected:string;
  client:string;
  type:string;
  carrier:string;
  owner:string;
  assignObj1;
  isFormValid:boolean;
  hideButtonsIfDriver:boolean = false;
  hideButtonsIfSupervisor:boolean = false;
  hideButtonsIfGuard:boolean = false;
  hideButtonsIfSpotter:boolean = false;
  filterTrailerStatusModal:boolean = false;
  dialogPositionLeft: number = 0 ; 
  dialogPositionTop: number = 0;
  appendToBody: boolean = true;
  trailerStatus = [
    {name:'Empty', code: 'EMPTY'},
    {name:'Loaded', code:'FULL'}
  ];
  selectedStatus:any[];
  selectedTrailerStatus:any=null;
  dropdownItems = [
    { name: 'Truck', code: 'TRUCK' },
    { name: 'Trailer', code: 'TRAILER' },
    { name: 'Container', code: 'CONTAINER' },

];
uniqueCarrierList : string[] = [];  
matchModeOptions: SelectItem[];
mergeCarrierNames: any[]= [];
carrierModel: boolean = false;
assignCarrierForm: FormGroup;
carrierFormValid: boolean = true;
uniqueCarriers: any[] = [];  
mergecarrier_required: boolean = false;
renameCarrierRequired: boolean = false;


//   products = [
//       {
//           type: 'Truck',
//           year: '2022',
//           make: 'make',
//           model: 'model',
//           color: 'color',
//           plateNumber: 'BG-1241',
//           remarks: 'Remarks',
//           carrier:"FX",
//           client:'Dynacraft',
//           companyAssociated:'Blair'
//       },
//       {
//         unitId:"2",
//         type: 'Trailer',
//         year: '2022',
//         make: 'make',
//         model: 'model',
//         color: 'color',
//         plateNumber: 'XV-5211',
//         remarks: 'Remarks',
//         carrier:"Rentco",
//         client:'Dynacraft',
//         companyAssociated:'Rental'
//     },
//     {
//       unitId:"3",
//       type: 'Truck',
//       year: '2022',
//       make: 'make',
//       model: 'model',
//       color: 'color',
//       plateNumber: 'VMS-123',
//       remarks: 'Remarks',
//       carrier:"JMB",
//       client:'Pegasus',
//       companyAssociated:'Blair'
//   },
//   {
//     unitId:"4",
//     type: 'Truck',
//     year: '2022',
//     make: 'make',
//     model: 'model',
//     color: 'color',
//     plateNumber: 'GM-2415',
//     remarks: 'Remarks',
//     carrier:"Clary",
//     client:'Pegasus',
//     companyAssociated:'Client'
// },
//   ];

  
    
@ViewChild('paginator', { static: true }) paginator: Paginator;
//@ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;

@ViewChildren('checkboxes') checkboxRefs: QueryList<Checkbox>;
  checkboxList: Checkbox[] = [];
  carrierLIst: { label: string; value: number; }[];



  constructor(
    private router:Router,
    private manageClientsService:ManageClientsService,
    private loader:AppLoaderService,
    private alertService:AppAlertService,
    private manageFleetsService:ManageFleetsService, 
    private fb : FormBuilder,
    private tokenService:TokenService,
    private errorService:ErrorService,
    private filterService: FilterService,
    private dataService: DataService,
    // private trailerTrackingService: ManageTrailerTrackingServiceService,
  ) {

    this.items = [
      {
          label: 'Excel',
          icon: 'pi pi-download',
          command: () => {
              this.exportExcel();
          }
      }
      // ,
      // {
      //     label: 'CSV',
      //     icon: 'pi pi-download',
      //     command: () => {
      //         this.exportCsv();
      //     }
      // }
  ];


    this.assignTrailerForm = this.fb.group({
      clientId: ['',Validators.required],
      fleetIds: ['',Validators.required]
  });

    this.assignUnitNumberForm = this.fb.group({
        unitNumber1: [],
        unitNumber2: [],
        // fleetIds:[]
        client:[],
        type:[],
        carrier:[],
        owner:[],
        // carrierName:[]
    });
    this.assignCarrierForm = fb.group({
      carrier: ['', Validators.required],
      renameCarriers: [[],Validators.required],
      // carrierName:[''],
      // newCarrier:['']
    });

  }

  ngAfterViewInit() {
    this.checkboxList = this.checkboxRefs.toArray();
  }

  ngOnInit() {
        this.isFormValid=true;
      this.label="Merge Trailer";
      this.i=0;
      this.j=0;
      this.userRoles = this.tokenService.getUserRoles();
      
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
      {
          this.hideButtonsIfDriver = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
      {
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR;
          this.hideButtonsIfSupervisor = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD))
      {
          this.hideButtonsIfGuard = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER))
      {
          this.hideButtonsIfSpotter = true;
      }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN))
        {
            this.userType = APP_CONSTANTS.USER_ROLES.ROLE_ADMIN;
        }
      if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_IT))
        {
            this.userType = APP_CONSTANTS.USER_ROLES.ROLE_IT;
        }
      this.accessToken = this.tokenService.getAccessToken();
      
      

      this.query = {isActive:true,size:50,page:0};
      this.getFleetList(this.query);

      this.clientQuery = {isActive:true,size:1000,page:0};
      this.getClientList(this.clientQuery);

      this.cols = [
          { field: 'unitId', header: 'Unit Id' },
          { field: 'type', header: 'Type' },
          { field: 'make', header: 'Make' },
          { field: 'year', header: 'Year' },
          { field: 'model', header: 'Model' }
      ];

      this.dropdownStatus = [
        {name:"Active",code:true},
        {name:"Inactive",code:false},
    ]

      this.breadcrumbItems = [];
      this.breadcrumbItems.push({ label: 'Asset Inventory'});
  }

  openAssignModal() {
    this.assignFleetModal = true;
}

  hideDialog() {
  this.assignFleetModal = false;
}

  hideDialog1(){
  this.isFormValid=true;
  this.assignUnitNumberModal = false;
  this.assignUnitNumberForm.value.unitNumber1="";
  this.assignUnitNumberForm.value.unitNumber2="";
  this.assignUnitNumberForm.value.fleetIds="";
  this.assignUnitNumberForm.value.client="";
  this.assignUnitNumberForm.value.type="";
  this.assignUnitNumberForm.value.carrier="";
  this.assignUnitNumberForm.value.owner="";
  this.unitNumber1="";
  this.unitNumber2="";
  this.spotselected="";
  this.selectedOption="no";
  this.client="";
  this.type="";
  this.carrier="";
  this.owner="";
  this.unitNumberList.pop();
  console.log("unitNumber list inside hideDialog1",this.unitNumberList);
}

hideCarrierModel(){
  this.carrierFormValid = true;
  this.carrierModel = false;
  // this.assignCarrierForm.reset();
  this.assignCarrierForm.patchValue({
    carrier: '',
    renameCarriers: [],
    // newCarrier: ''
  });
  this.mergecarrier_required = false;
  this.renameCarrierRequired = false;
}

  getFleetList(query: IpagedQuery,clientId?: any,unitNumber?:any,selectedTrailerStatus?:any) {
  
    this.loading = true;
    this.subscription.add(
      this.manageFleetsService.viewFleets(query,clientId,unitNumber,false,selectedTrailerStatus)
      // .pipe(
      //   map(res=>{
      //     let fleets = [];
      //     for(let fleet of res.list){
      //       let obj = {
      //         ...fleet,
      //         plateNumber_unitNumber : fleet.plateNumber+"-"+`(${fleet.unitNumber})`
      //       };
      //       fleets.push(obj);
      //     }
      //     return { 
      //       list : fleets,
      //       totalElements : res.totalElements
      //     }
      //   })
      // )
      .subscribe(response=>{
        this.fleetList = response.list;
        this.totalRecords = response.totalElements;
        this.tallyObj = response.tally;
        this.loading = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    ) 
  }
 
  getClientList(clientQuery) {
    this.loading = true;
    this.subscription.add(
      this.manageClientsService.viewClients(clientQuery).subscribe(response=>{
        this.clientList = response.list;
        this.loading = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  assignFleetToClient(){
    if(this.userType == APP_CONSTANTS.USER_ROLES.ROLE_CLIENT){
      this.assignTrailerForm.patchValue({
        clientId: this.tokenService.getUserClientId()
      })
    }
    if(this.assignTrailerForm.invalid){
      this.assignTrailerForm.markAllAsTouched();
    }else {
      this.loader.show();
      let assignObj = {
        fleetIds : this.assignTrailerForm.value.fleetIds
      }
      this.manageFleetsService.assignFleetToClient(assignObj,this.assignTrailerForm.value.clientId).subscribe(res=>{
        this.loader.hide();
        this.alertService.alertSuccess(['Succesfully assigned to Client']);
        this.assignFleetModal = false;
        this.assignTrailerForm.reset();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    }
 }

  routeToAddFleet(){
      this.router.navigate(['/main/add-fleet'])
  }

  routeToEditFleet(fleetId){
    this.router.navigate(['/main/add-fleet'],{queryParams:{fleetId}})
  }

  routeToAddJob(){
    let data = {breadcrumb: [
      { label: 'Asset Inventory',url:'../manage-fleets'},
    ]}
    localStorage.setItem('breadcrumb', JSON.stringify(data));
    // this.dataService.setData(data);
    this.router.navigate(['/main/add-job'], {queryParams:{locationBack:true}});
  }

 /* paginate(event){
    this.query.page = event.page;
    this.filterByClient ? this.getFleetList(this.query,this.filterByClient) : this.getFleetList(this.query);
  }
*/

private updateCurrentPage(currentPage: number): void {
  setTimeout(() => this.paginator.changePage(currentPage));
}

paginate(event){
  console.log("hello")
  this.query.page = event.page;
  console.log(this.query);
  console.log(this.filterByClient);
  //  console.log(this.unitNumber); 
  console.log(this.selectedTrailerStatus);
  this.getFleetList(this.query,this.filterByClient,this.unitNumber,this.selectedTrailerStatus);
}


  filterFleetsByClient(event){
    console.log(event)
    this.filterByClient = event.value;
    this.query.page=0;
    if(this.fleetList.length==0)
    {
      
      this.getFleetList(this.query,event.value,this.unitNumber);
    }   
    else
    {
      console.log("else");
      this.updateCurrentPage(0);
    }
  }

  ngOnDestroy(): void {    
    this.subscription.unsubscribe()
  }

  filterFleetsByStatus(event){
    
    console.group(event.value);
    this.query.isActive = event.value;
    this.query.page=0;
    if(this.fleetList.length==0)
    {
      this.getFleetList(this.query,this.filterByClient,this.unitNumber);
    }
    else
    {
      this.updateCurrentPage(0);
    } 
    
}

search() {

  if(this.searchboxValue!="")
  {
    this.i++;
    this.unitNumber=this.searchboxValue;
    setTimeout(() => {
      this.j++;
    
    if(this.i==this.j)
    {
    
    this.query.page=0;
    this.updateCurrentPage(0);

    if(this.fleetList.length==0)
    {
    this.getFleetList(this.query,this.filterByClient,this.searchboxValue);
    }
    
   // 
    
    }
    
    // And any other code that should run only after 5s
  }, 1000);
  }
  else
  {
    this.unitNumber="";
   // this.getFleetList(this.query,this.filterByClient);
    this.updateCurrentPage(0);
    this.query.page=0;
    if(this.fleetList.length==0)
    {
    this.getFleetList(this.query,this.filterByClient,this.searchboxValue);
    }
  }
  
}

  exportCsv(){
    if(this.filterByClient){
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/export/csv?access_token=${this.accessToken}&isActive=${this.query.isActive}&clients.uuid=${this.filterByClient}`, '_blank');
    }else{
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/export/csv?access_token=${this.accessToken}&isActive=${this.query.isActive}`, '_blank');
    }
  }

  exportExcel(){
    if(this.filterByClient){
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/export/excel?access_token=${this.accessToken}&isActive=${this.query.isActive}&clients.uuid=${this.filterByClient}`, '_blank');
    }else{
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/fleets/export/excel?access_token=${this.accessToken}&isActive=${this.query.isActive}`, '_blank');
    }
  }

  activateFleet(fleetId){
    this.manageFleetsService.activateFleet(fleetId).subscribe(res=>{
      this.alertService.alertSuccess([`Activated Successfully`]);
      this.getFleetList(this.query);
      this.loader.hide();
  },(error) => {
    this.loader.hide();
    this.errorService.handleError(error, true);
  })
  }

  // clearFilter(){
  //   this.filterByClient = null;
  //   this.getFleetList(this.query);

  // }


  // cancel()
  // {
  //   this.editRefreshButtonVisibility=true;
  //   this.checkboxDisplay = 'none';
  //   this.checkboxes.forEach((element) => {
  //     element.nativeElement.checked = false;
  //   });
  //   this.unitNumberList=[];
  //   this.buttonDisable=false;
  //   this.cancelButtonDisable=true;
    
    

  // }


    cancel()
    {
    this.getFleetList(this.query,this.filterByClient,this.searchboxValue);
    this.editRefreshButtonVisibility=true;
    this.checkboxDisplay = 'none';
    this.unitNumberList=[];
    this.buttonDisable=false;
    this.cancelButtonDisable=true;
    this.label="Merge Trailer";
    }




  showCheckbox()
  {
    this.label="Merge Selected";
    if(this.unitNumberList.length>0)
    {
      this.unitNumberList.push("Assign new unit#");
    }
    console.log(this.unitNumberList);
    this.editRefreshButtonVisibility=false;
    this.cancelButtonDisable=false;
    this.buttonDisable=true;
    if(this.unitNumberList.length>1)
    {
     this.buttonDisable=false;
    }
   
    if (this.checkboxDisplay === 'none') {
      this.checkboxDisplay = 'block';
      this.unitNumberList=[];
      // this.unitNumberList.push("Assign new Fields");
      this.mergeSpotList=[];
    } else {
      this.assignUnitNumberModal=true;
      this.owner = "A.Blair";
     
    }
  }


  selectForMerge(event,fleet:any)
  {
    
   if(event.target.checked)
    { 
     console.log(event);
     if(fleet.spot)
     {
      this.mergeSpotList.push(fleet.spot);
     }

     this.unitNumberList.push(fleet.unitNumber);
     
     console.log(this.unitNumberList);
    }
    if(!event.target.checked)
    {
      
      this.unitNumberList.forEach((element,index)=>{
         if(element==fleet.unitNumber)
         this.unitNumberList.splice(index,1);
      });
      if(fleet.spot)
      {

        this.mergeSpotList.forEach((element,index)=>{
          if(element==fleet.spot)
          this.mergeSpotList.splice(index,1);
       });
      }
      
    }
    
   
    if(this.unitNumberList.length>1)
    {
      this.buttonDisable=false;
    }
    else
    {
      this.buttonDisable=true;
    }
    
  }

  assignUnitNumberToMerge(){

    
    if(this.assignUnitNumberForm.invalid){
      this.assignUnitNumberForm.markAllAsTouched();
    }else {
      //this.loader.show();

      // let assignObj1;
      // if (this.assignUnitNumberForm.value.fleetIds)
      // {
      //   if(this.assignUnitNumberForm.value.unitNumber2)
      //   {
      //    assignObj1 = {
      //     selectedUnitNumber : this.assignUnitNumberForm.value.unitNumber1,
      //     assignedUnitNumber : this.assignUnitNumberForm.value.unitNumber2,
      //     selectedSpot: this.assignUnitNumberForm.value.fleetIds,
      //     unitNumberList : this.unitNumberList
      //   }
      // }
      // else
      // {

      //   assignObj1 = {
      //     selectedUnitNumber : this.assignUnitNumberForm.value.unitNumber1,
      //     selectedSpot: this.assignUnitNumberForm.value.fleetIds,
      //     unitNumberList : this.unitNumberList
      //   }
      // }
      // }
      // const firstElement = this.unitNumberList.find(element => element === "Assign new Fields");
      // if(!firstElement)
      // {
      //   this.unitNumberList.shift();
      // }

      // 
      


    if(!this.assignUnitNumberForm.value.unitNumber1 || !this.assignUnitNumberForm.value.client)
    {
      this.isFormValid=false;
    }
    if(this.unitNumber1=="Assign new unit#")
    {
      if(!this.assignUnitNumberForm.value.unitNumber2 || !this.assignUnitNumberForm.value.type
        || !this.assignUnitNumberForm.value.carrier || !this.assignUnitNumberForm.value.owner)
        {
          this.isFormValid=false;
        }
    }

    if(this.isFormValid==true)
    {
      this.loader.show();
      this.unitNumberList.pop();
       
      if(this.unitNumber1=="Assign new unit#")
      {
        
        this.assignObj1 ={
          selectedUnitNumber : this.unitNumberList[0]
        };
      }
      else
      {
        this.assignObj1 = {
          selectedUnitNumber : this.assignUnitNumberForm.value.unitNumber1,
         
          };
      }

      this.assignObj1 ={
        ...this.assignObj1,
        unitNumberList : this.unitNumberList
      };

     

        if(this.assignUnitNumberForm.value.unitNumber2)
        {
         this.assignObj1 = {
          ...this.assignObj1,
          assignedUnitNumber : this.assignUnitNumberForm.value.unitNumber2
        }
      }
      if(this.assignUnitNumberForm.value.client)
        {
         this.assignObj1 = {
          ...this.assignObj1,  
          client : this.assignUnitNumberForm.value.client
        }
      }
      if(this.assignUnitNumberForm.value.type)
        {
         this.assignObj1 = {
          ...this.assignObj1,
          type: this.assignUnitNumberForm.value.type
        }
      }
      if(this.assignUnitNumberForm.value.carrier)
        {
        this.assignObj1 = {
          ...this.assignObj1,
          carrier: this.assignUnitNumberForm.value.carrier
        }
      }
      if(this.assignUnitNumberForm.value.owner)
      {
       this.assignObj1 = {
        ...this.assignObj1,
        owner: this.assignUnitNumberForm.value.owner
      }
    }
       
      console.log(this.assignObj1);
       this.manageFleetsService.mergeUnitNumber(this.assignObj1).subscribe(res=>{
         this.loader.hide();
         this.alertService.alertSuccess(['Succesfully merged Unit Numbers']);
         this.assignUnitNumberModal = false;
         this.mergeSpotList=[];
         this.unitNumberList=[];
         this.getFleetList(this.query,this.filterByClient,this.unitNumber);
         this.assignUnitNumberForm.reset();
         this.checkboxDisplay = 'none';
        //  this.checkboxes.forEach((element) => {
        //   element.nativeElement.checked = false;
        // });
        this.editRefreshButtonVisibility=true;
        this.buttonDisable=false;
        this.cancelButtonDisable=true
        this.label="Merge Unitnumber";
        console.log(this.unitNumberList);
        console.log(this.mergeSpotList);
        this.selectedOption="no";
        
       },(error) => {
         this.loader.hide();
         this.errorService.handleError(error, true);
       })

    }
    
      
    }


  }
handleChange1(event,fleet:any)
{
  if(event.checked.length>0)
   { 
    console.log(event);
    if(fleet.spot)
    {
     this.mergeSpotList.push(fleet.spot);
    }

    this.unitNumberList.push(fleet.unitNumber);
    
    console.log(this.unitNumberList);
   }
   if(event.checked.length==0)
   {
     console.log(event);
     this.unitNumberList.forEach((element,index)=>{
        if(element==fleet.unitNumber)
        this.unitNumberList.splice(index,1);
     });
     if(fleet.spot)
     {

       this.mergeSpotList.forEach((element,index)=>{
         if(element==fleet.spot)
         this.mergeSpotList.splice(index,1);
      });
     }
     
   }
   
  
   if(this.unitNumberList.length>1)
   {
     this.buttonDisable=false;
   }
   else
   {
     this.buttonDisable=true;
   }
   
}

// selectOption(optionType: string) {
//   this.selectedOption = optionType;
//   if(optionType=='no')
//   {
//    this.assignUnitNumberForm.value.unitNumber2="";
//    this.unitNumber2="";
//   }

// }



  selectOption(event)
  {

    this.isFormValid=true;
    if(event.value=="Assign new unit#")
    {
      this.selectedOption="text";
    }
    else
    {
      this.selectedOption="no";
    }
  }
  
  selectOption1(event)
  {
    this.isFormValid=true;
  }
  selectOption2(event)
  {
    this.isFormValid=true;
  }
  selectOption3(event)
  {
    this.isFormValid=true;
  }
  selectOption4(event)
  {
    this.isFormValid=true;
  }
  selectOption5(event)
  {
    this.isFormValid=true;
  }

  closeMergeTrailerPopup(){
    this.assignUnitNumberModal=false;
  }

selectTrailerStatus(event:any,op:any)
{

  if(op){
    op.hide();
  }
  console.log(event);
  if(event.value!=null) 
  {
    this.selectedTrailerStatus = event.value;
  }
  else
  {
    this.selectedTrailerStatus = null;
  }

  this.getFleetList(this.query,this.filterByClient,this.unitNumber,this.selectedTrailerStatus);
  
}

getCarrier(event) {
  
  console.log(event);
  
  // this.trailerTrackingService.getCarriers(this.clientQuery, event.query)
  this.manageFleetsService.getCarrier(event.query)
    .subscribe(response=>{
  
      this.uniqueCarrierList = response;
      
      // this.carrierLIst = this.uniqueCarrierList.map((label, index) => ({ label, value: index + 1 }));
      console.log(this.uniqueCarrierList);
      this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  });

}

clearCarrier()
{
  this.uniqueCarrierList = null;
}

mergeCarrier(){
  this.carrierModel = true;
  // this.assignCarrierForm.patchValue({
  //   carrier: '',
  //   newCarrier: ''
  // });
  // this.trailerTrackingService.getCarriers(this.clientQuery)
  this.manageFleetsService.getCarrier("")
  .subscribe(response=>{
    this.uniqueCarrierList = response;
    this.carrierLIst = this.uniqueCarrierList.map((label, index) => ({ label, value: index + 1 }));
    this.uniqueCarriers = response;
  },(error) => {
    
    this.errorService.handleError(error, true);
});
}

saveCarrierMerge(){
  console.log("val",this.assignCarrierForm);
  
  if(this.assignCarrierForm.invalid){
    console.log("enter1");
    
    if(this.assignCarrierForm.value.carrier.trim() == "" && this.assignCarrierForm.value.renameCarriers.length == 0){
      this.carrierFormValid = false;
      this.mergecarrier_required = false;
      this.renameCarrierRequired = false;
    }else if(this.assignCarrierForm.value.carrier.trim() == ""){
      this.mergecarrier_required = true;
      this.carrierFormValid = true;
      this.renameCarrierRequired = false;
    }else if(this.assignCarrierForm.value.renameCarriers.length == 0){
      this.renameCarrierRequired = true;
      this.mergecarrier_required = false;
      this.carrierFormValid = true;
    }
    
  }else{
    console.log("e2");
    
    this.carrierFormValid = true;
    this.loader.show();
    // if((this.assignCarrierForm.value.carrier.trim() == "" || this.assignCarrierForm.value.carrier.trim() == undefined || this.assignCarrierForm.value.carrier.trim() == null) && (this.assignCarrierForm.value.newCarrier.trim() == "" || this.assignCarrierForm.value.newCarrier.trim() == undefined || this.assignCarrierForm.value.newCarrier.trim() == null)){
      if(this.assignCarrierForm.value.carrier.trim() == "" || this.assignCarrierForm.value.carrier.trim() == undefined || this.assignCarrierForm.value.carrier.trim() == null){
      this.mergecarrier_required = true;
      this.renameCarrierRequired = false;
      this.loader.hide();
    }else{
      console.log("e3");
      
      this.mergecarrier_required = false;
      this.renameCarrierRequired = false;
      this.manageFleetsService.mergeCarrier(this.assignCarrierForm.value)
      .subscribe(response=>{
        console.log("e4");
        
        this.carrierModel = false;
        this.assignCarrierForm.reset();
        this.loader.hide();
        this.getFleetList(this.query);
        this.alertService.alertSuccess(['Succesfully carriers merged']);
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    });
    }
    
  }
}

saveTypedData(event){
  
  // if(this.uniqueCarriers.find(item => item.carrierId === this.assignCarrierForm.value.carrierName?.carrierId) == undefined){
  //   this.assignCarrierForm.get('newCarrier').setValue(this.assignCarrierForm.value.carrierName);
  //   this.assignCarrierForm.get('carrier')?.setValue("");
    
  // }else{
  //   this.assignCarrierForm.get('newCarrier').setValue("");
  // }
  if(!this.uniqueCarriers.includes(event.query)){
    this.assignCarrierForm.setValue(event.query);
  }
  
}

// onCarrierSelect(event: any) {
//   this.assignCarrierForm.get('carrier')?.setValue(event.carrierId);
//   this.assignCarrierForm.get('newCarrier').setValue("");
// }

// onCarrierSelectFleet(event: any) {
//   this.assignUnitNumberForm.get('carrier')?.setValue(event.carrierId);
// }
  
}