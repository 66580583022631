import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { combineLatest, filter, forkJoin, map, mergeMap, of, Subscription, switchMap } from 'rxjs';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { TokenService } from 'src/app/security/token.service';
import { AddUserService } from '../add-user/add-user.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { ManageJobsService } from '../manage-jobs/manage-jobs.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { AddJobService } from '../add-job/add-job.service';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-add-spots-popup',
  templateUrl: './add-spots-popup.component.html',
  styleUrls: ['./add-spots-popup.component.scss']
})
export class AddSpotsPopupComponent implements OnInit, OnDestroy {

  selectedLocation:any;
  selectedSpot:any;
  selectedDropLocation:any;
  selectedDropSpot:any;
  selectedTrailer:any;
  selectedDriver:any;

  breadcrumbItems: MenuItem[];

    locations = [];

    pickupSpots = [];

    dropLocations = [];

    dropSpots = [];

    trailers = [];

    trailerStatus = [
      { name: 'Empty', code: 'EMPTY' },
      { name: 'Loaded', code: 'FULL' }
    ];

    drivers = [];

  jobForm: FormGroup;
  query:IpagedQuery;
  query1:IpagedQuery;
  subscription = new Subscription();
  clientId;
  locationId;
  userId: any;
  userClients: any;
  clientsLocationsList = [];
  jobId: any;
  clientList = [];
  userRoles: any;
  userType: any;
  jobQuery: IpagedQuery;
  job: any;
  isDriver: boolean;
  isSpotter:boolean;
  deleteJobDialog: boolean;
  filteredFleets: any[];
  uniqueFleetId: any;
  uniqueClientId: any;
  isInputTouched:boolean;
  hideButtonsIfSpotter:boolean = false;

  constructor(private manageLocationsService:ManageLocationsService,
    private manageSpotsService:ManageSpotsService,
    private manageFleetsService:ManageFleetsService,
    private manageClientsService:ManageClientsService,
    private loader:AppLoaderService,
    private fb:FormBuilder,
    private manageUsersService:ManageUsersService,
    private tokenService:TokenService,
    private addUserService:AddUserService,
    private addJobService:AddJobService,
    private alertService:AppAlertService,
    private router:Router,
    private activatedRoute:ActivatedRoute,
    private errorService:ErrorService,
    private manageJobsService:ManageJobsService,
    private dialogConfig: DynamicDialogConfig,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
      this.jobForm = this.fb.group({
        assignedToUserId: ['',Validators.required],
        description: [''],
        dropLocationId: ['',Validators.required],
        dropSpotId: [''],
        fleetId: ['',Validators.required],
        unitNumber: [''],
        fleetStatus: [null],
        pickupLocationId: ['',Validators.required],
        pickupSpotId: ['',Validators.required],
        priority: ['',Validators.required],
        clientId: ['',Validators.required],
        isEdit:[''],
        fleetAndHotTrailer: ['', Validators.required]
      });

    }

  ngOnInit(): void {

    this.job = this.dialogConfig.data;

    this.uniqueClientId=null;
    this.isInputTouched=false;
    this.query = {isActive:true,size:1000,page:0};
    this.query1 = {isActive:true,size:3000,page:0};
    this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
        this.getClientLocations(this.query,this.tokenService.getUserClientId());
        this.viewUsers(this.query,this.tokenService.getUserClientId());
      }

      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER)){
       this.isDriver = true;
      }

      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER)){
       this.hideButtonsIfSpotter = true;
       this.isSpotter = true;
      }

    this.userId = this.tokenService.getUserId();
    this.getClientList(this.query);



    this.job = this.dialogConfig.data;

    console.log(this.job);

    this.jobForm.patchValue({
      clientId: this.job.clientId ? this.job.clientId:"",
      unitNumber: this.job.unitNumber ? this.job.unitNumber:"",
      fleetId: this.job.fleetId ? this.job.fleetId:"",
      pickupLocationId: this.job.locationId ? this.job.locationId:"",
      pickupSpotId: this.job.spotId ? this.job.spotId:"",
      fleetStatus: this.job.fleetStatus ? this.job.fleetStatus:"",
      fleetAndHotTrailer: this.job.fleetId ? this.checkIfHotTrailer(this.job) : '',
    });

    this.uniqueFleetId = this.job.fleetId ? this.job.fleetId:"";

    if(this.job.clientId != null && this.job.clientId != ""){
      
      this.getClientLocations(this.query,this.job.clientId, 1);
      this.viewUsers(this.query,this.job.clientId);
      this.getClientFleetList(this.query1,this.job.clientId);
      this.uniqueClientId = this.job.clientId;
    }
    
   
  }


  removeTextBeforeColon(text) {
    let parts = text.split(':');
    if (parts.length > 1) {
      return parts[1].trim();
    }
    return text.trim(); // Return trimmed text if ':' is not found
  }

  getJobById(id,query){
    this.manageJobsService.viewJobs(query).subscribe(res=>{
      this.job = res.list.find(job => job.jobId == id);
      
      if(this.job.description)
      {
        
        var description = this.removeTextBeforeColon(this.job.description);
        this.job.description = description;
      }



      this.getClientLocations(this.query,this.job.pickupLocation.clientId);
      this.viewUsers(this.query,this.job.pickupLocation.clientId);
      this.getSpots(this.query,this.job.pickupLocation.clientId,this.job.pickupLocation.locationId,'pickup');
      this.getSpots(this.query,this.job.dropLocation.clientId,this.job.dropLocation.locationId,'drop');
      this.getClientFleetList(this.query,this.job.pickupLocation.clientId)
      this.uniqueClientId = this.job.pickupLocation.clientId;

        this.jobForm.patchValue({
          clientId : this.job.pickupLocation.clientId ? this.job.pickupLocation.clientId : '',
          assignedToUserId: this.job.assignedTo.userId ? this.job.assignedTo.userId : '',
          description: this.job.description ? this.job.description : '',
          dropLocationId: this.job.dropLocation.locationId ? this.job.dropLocation.locationId : '',
          dropSpotId: this.job.dropSpot ? this.job.dropSpot.spotId : '',
          fleetId: this.job.fleet.fleetId ? this.checkIfHotTrailer(this.job.fleet) : '',
          fleetStatus: this.job.fleetStatus ? this.job.fleetStatus : '',
          pickupLocationId: this.job.pickupLocation.locationId ? this.job.pickupLocation.locationId : '',
          pickupSpotId: this.job.pickupSpot ? this.job.pickupSpot.spotId : '',
          priority: this.job.priority ? this.job.priority : ''
        })

        this.uniqueFleetId = this.job.fleet.fleetId ? this.job.fleet.fleetId : null;


    })
  }

  getClientList(query) {
    // this.loader.show()
      this.manageClientsService.viewClients(query).subscribe(response=>{
        this.clientList = response.list;
        // this.loader.hide();
      },(error) => {
        // this.loader.hide();
        this.errorService.handleError(error, true);
    })
  }

  getUserById(userId){
    this.loader.show();
    this.addUserService.getUserById(userId)
    .pipe(
      filter(res => res.clients ? res : this.loader.hide()),
      mergeMap(res=>
        combineLatest(
          [forkJoin(res['clients'].map(client=>this.manageLocationsService.viewLocations(this.query,client.clientId))),
          of(res)])),
          map(([locations,res])=>{
            return locations
      })
    ).subscribe((locations:[]) => {
      locations.map(loc=>{
        this.clientsLocationsList.push(loc["list"])
      })
      this.clientsLocationsList = this.flatten(this.clientsLocationsList);
      this.loader.hide();
      // uwiWJZux
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  });
  }

  flatten(arr) {
    return arr.reduce((flat, toFlatten)=>{
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, []);
  }

  viewUsers(query:IpagedQuery,clientId?:any){
    // this.loader.show();
    this.subscription.add(
      this.manageUsersService.viewUsers(query,clientId).
      pipe(
        map(res=>{
          let users = [];
          for(let user of res.list){
            let obj = {
              ...user,
              fullName : user.firstName+" "+user.lastName
            };
            users.push(obj);
          }
          return users
        })
      ).subscribe(users=>{
        let usersList=[];
        users.map(user=>{
          for(let role of user["roles"]){

            if(role.roleName === "DRIVER" || role.roleName === "SPOTTER" || role.roleName === "SUPERVISOR"){
              usersList.push(user);
            }
          }
        })
        this.drivers = usersList;
        if(this.isDriver || this.isSpotter){
          this.drivers = this.drivers.filter(user=> user.userId === this.userId);
        }
        // this.loader.hide();
      },(error) => {
        // this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
  }

  getFleetList(query: IpagedQuery) {
    this.loader.show();
    this.subscription.add(
      this.manageFleetsService.viewFleets(query)
      .pipe(
        map(fleets=>{
          let fleetsArray = [];
          for(let fleet of fleets.list){
              let obj = {
                  ...fleet,
                  fleetAndHotTrailer : this.checkIfHotTrailer(fleet)
                };
                fleetsArray.push(obj);
          }
          return {
            list : fleetsArray
          }

        })
      ).subscribe(response=>{
        this.trailers = response.list;
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
  }

  checkIfHotTrailer(fleet) {
    if(fleet.isHotTrailer){
      return `${fleet.unitNumber} - (Hot Trailer)`
    }else{
      return `${fleet.unitNumber}`
    }
  }

  getClientFleetList(query1: IpagedQuery,clientId?:any) {
    // this.loader.show();
    this.subscription.add(
      this.manageFleetsService.viewFleets(query1,clientId)
      .pipe(
        map(fleets=>{
          let fleetsArray = [];
          for(let fleet of fleets.list){
              let obj = {
                  ...fleet,
                  fleetAndHotTrailer : this.checkIfHotTrailer(fleet)
                };
                fleetsArray.push(obj);
          }
          return {
            list : fleetsArray
          }

        })
      ).subscribe(response=>{
        this.trailers = response.list;
        // this.loader.hide();
      },(error) => {
        // this.loader.hide();
        this.errorService.handleError(error, true);
    })
    )
  }

  getClientLocations(query,clientId?:any,isClientChanged:any=0) {
    // this.loader.show();
    this.subscription.add(
        this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
            this.clientsLocationsList = response.list;
            if(isClientChanged > 0){
              if(this.job.locationId != null && this.job.locationId != ""){
                this.onChange({value: this.job.locationId}, "pickup"); // loading pickup locations
              }
              
            }
            
            // this.loader.hide();
        },(error) => {
          // this.loader.hide();
          this.errorService.handleError(error, true);
      })
    )
  }



  onChange(event,type) {
    this.loader.show();
    let location = this.clientsLocationsList.find(loc => loc.locationId == event.value);
    this.manageSpotsService.viewDropdownSpots(this.query,location.clientId,location.locationId)
    .pipe(
      map(spots=>{
        let spotsArray = [];
        for(let spot of spots.list){
            let obj = {
                ...spot,
                spotAndStatus : this.spotAndFleetStatus(spot)
              };
              spotsArray.push(obj);
        }
        return {
          list : spotsArray,
          totalElements : spots.totalElements
        }

      })
    ).subscribe(res=>{
      type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
      this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  })
  }



  onSubmit(){
    if(this.jobForm.invalid){
      this.jobForm.markAllAsTouched();
    }else{
      if(!this.uniqueFleetId){
        this.jobForm.markAllAsTouched();
      }else{
        // this.jobForm.patchValue({
        //   fleetId: this.uniqueFleetId
        // })
      }
      this.loader.show();
      if(this.jobId){

        this.jobForm.patchValue({
          isEdit: true
        })
        this.addJobService.updateJob(this.jobId,this.jobForm.value).subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['Spot Updated Successfully']);
          // this.router.navigate(['main/overview']);
          // this.dialogService.dialogComponentRefMap.forEach(dialog => {
          //   dialog.destroy();
          // });
          window.location.reload();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
      }else{
        this.addJobService.saveJob(this.jobForm.value).subscribe(res=>{
          this.loader.hide();
          this.alertService.alertSuccess(['Spot Added Successfully']);
          // this.router.navigate(['main/overview'])
          // this.dialogService.dialogComponentRefMap.forEach(dialog => {
          //   dialog.destroy();
          // });
          window.location.reload();
        },(error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
      })
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  onClientSelect(event){
    console.log(event.value);
    
    this.jobForm.get('fleetId').reset(); 
    this.jobForm.get('fleetAndHotTrailer').reset(); 

    this.getClientLocations(this.query,event.value);
    this.viewUsers(this.query,event.value);
    this.getClientFleetList(this.query1,event.value);
    this.uniqueClientId = event.value;
   
  }

  getSpots(query,clientId,locationId,type){
    this.loader.show();
    this.manageSpotsService.viewSpots(query,clientId,locationId)
    .pipe(
      map(spots=>{
        let spotsArray = [];
        for(let spot of spots.list){
            let obj = {
                ...spot,
                spotAndStatus : this.spotAndFleetStatus(spot)
              };
              spotsArray.push(obj);
        }
        return {
          list : spotsArray,
          totalElements : spots.totalElements
        }

      })
    ).subscribe(res=>{
      type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
      this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  spotAndFleetStatus(spot){
    if(spot.status == 'EMPTY' && spot?.fleet == null){
      return `${spot.spotName} - Empty`
    }else if(spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL'){
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Full Trailer`
    }else if(spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY'){
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Empty Trailer`
    }else if(spot.status == 'TO_BE_EMPTY'){
      return spot?.fleet == null ? `${spot.spotName} - Scheduled for Pick-Up` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Scheduled for Pick-Up`
    }else if(spot.status == 'TO_BE_OCCUPIED'){
      return spot?.fleet == null ? `${spot.spotName} - Location Reserved` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Location Reserved` 
    }else{
      return `${spot.spotName}`
    }

  }

  deleteJob() {
    this.deleteJobDialog = true;
  }

  confirmDelete(){
    this.loader.show();
    this.subscription.add(
    this.addJobService.deleteJob(this.jobId).subscribe(res=>{
        this.deleteJobDialog = false;
        this.alertService.alertSuccess([`Deleted Successfully`]);
        this.router.navigate(['main/manage-jobs'])
        this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
  })
    )
  }

  filterFleets(event) {
    if(this.uniqueClientId!=null)
    {
      this.manageFleetsService.viewFleets(this.query,this.uniqueClientId,event.query)
      .pipe(
        map(fleets=>{
          let fleetsArray = [];
          for(let fleet of fleets.list){
              let obj = {
                  ...fleet,
                  fleetAndHotTrailer : this.checkIfHotTrailer(fleet)
                };
                fleetsArray.push(obj);
          }
          return {
            list : fleetsArray
          }

        })
      ).subscribe(response=>{
        this.filteredFleets = response.list;

        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    });

    }
    
  }

  onFleetSelect(event){
    this.jobForm.patchValue({
      fleetId: event.fleetId,
      fleetAndHotTrailer: event.fleetAndHotTrailer
    })
    this.uniqueFleetId = event.fleetId ? event.fleetId : null;
  }

  clearUniqueId(event){
    this.uniqueFleetId = null;
  }

  onUnitNumberChange(event){
    console.log(event)
    this.jobForm.patchValue({
      fleetId: "",
    })
    this.uniqueFleetId = "";
  }

}
