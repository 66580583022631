import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { IpagedQuery } from 'src/app/model/IpagedQuery';

@Injectable({
  providedIn: 'root'
})
export class ManageTrailerTrackingServiceService {

  constructor(private http: HttpClient) { }

  getSuppliers(query:IpagedQuery, supplier?:any, sort?:any): Observable<any> {
    
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive != null ? query.isActive.valueOf() : '',
        page:query.page.toString(),
        size:query.size.toString(),
        
      }
    })
    if(supplier){
      params = params.append("supplier",supplier.toString())
    }

    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/supplier`,{params});
  }

  getCarriers(query:IpagedQuery, carrier?:any): Observable<any> {
    
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive != null ? query.isActive.valueOf() : '',
        page:query.page.toString(),
        size:query.size.toString(),
        
      }
    })
    if(carrier){
      params = params.append("carrier",carrier.toString())
    }
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier`,{params});
  }

  createSupplier(data: any):Observable<any>{
    return this.http.post(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/supplier`,data);
  }

  editSupplier(data: any, id: any):Observable<any>{
    return this.http.put(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/supplier/${id}`,data);
  }

  deleteSupplier(id: any):Observable<any>{
    return this.http.delete(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/supplier/${id}`);
  }

  getCarriersPage(query:IpagedQuery): Observable<any> {
    
    var params = new HttpParams({
      fromObject:{
        isActive:query.isActive != null ? query.isActive.valueOf() : '',
        page:query.page.toString(),
        size:query.size.toString(),
        
      }
    })
   
    return this.http.get(`${APP_CONSTANTS.BASE_API_URL}/${APP_CONSTANTS.VERSION}/carrier/list`,{params});
  }

}
