import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { combineLatest, concatMap, filter, forkJoin, map, mergeMap, of, Subscription, switchMap } from 'rxjs';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { TokenService } from 'src/app/security/token.service';
import { AddUserService } from '../add-user/add-user.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { ManageJobsService } from '../manage-jobs/manage-jobs.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { AddJobService } from './add-job.service';
import { DataService } from 'src/app/service/data.service';
import {Location} from '@angular/common';
import { AddFleetService } from '../add-fleet/add-fleet.service';
// import { ManageTrailerTrackingServiceService } from '../manage-trailer-tracking/manage-trailer-tracking-service.service';

@Component({
  selector: 'app-add-job',
  templateUrl: './add-job.component.html',
  styleUrls: ['./add-job.component.scss']
})
export class AddJobComponent implements OnInit, OnDestroy {

  selectedLocation: any;
  selectedSpot: any;
  selectedDropLocation: any;
  selectedDropSpot: any;
  selectedTrailer: any;
  selectedDriver: any;

  breadcrumbItems: MenuItem[];

  locations = [
    // { name: '7100 Winstead Dr. Louisville, KY', code: 'TX' }
  ];

  pickupSpots = [
    // { name: 'D37', code: 'TX' }
  ];

  dropLocations = [
    // { name: '7101 Winstead Dr. Louisville, KY', code: 'TX' }
  ];

  dropSpots = [
    // { name: 'D80', code: 'TX' }
  ];

  trailers = [
    // { name: '12NZ4566', code: 'TX' }
  ];

  trailerStatus = [
    { name: 'Empty', code: 'EMPTY' },
    { name: 'Loaded', code: 'FULL' }
  ];

  drivers = [
    
  ];

  jobForm: FormGroup;
  query: IpagedQuery;
  query1: IpagedQuery;
  subscription = new Subscription();
  clientId;
  locationId;
  userId: any;
  userClients: any;
  clientsLocationsList = [];
  jobId: any;
  clientList = [];
  userRoles: any;
  userType: any;
  jobQuery: IpagedQuery;
  job: any;
  isDriver: boolean;
  isSpotter: boolean;
  deleteJobDialog: boolean;
  filteredFleets: any[] = [];
  uniqueFleetId: any;
  uniqueClientId: any;
  isInputTouched: boolean;
  hideButtonsIfSpotter: boolean = false;
  notAdminIT: boolean = false;
  locationBack: boolean = false;
  isSupervisorOrClient: boolean = false;
  addFleetDialog: boolean = false;
  fleetForm: FormGroup;
  dropdownItems = [
    { name: 'Truck', code: 'TRUCK' },
    { name: 'Trailer', code: 'TRAILER' },
    { name: 'Container', code: 'CONTAINER' },

  ];
  uniqueCarrierList: string[] = [];
  addFleetDialogConfirm: boolean = false;
  trailerSubmitted: boolean = false;
  addTrailerHeader: String = "Confirm";
  isNewTrailer: boolean = false;
  submitted: boolean = false;
  status: string;
  isTrailerFoundInList: boolean = false;
  isBucket : boolean = false;

  constructor(private manageLocationsService: ManageLocationsService,
    private manageSpotsService: ManageSpotsService,
    private manageFleetsService: ManageFleetsService,
    private manageClientsService: ManageClientsService,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private manageUsersService: ManageUsersService,
    private tokenService: TokenService,
    private addUserService: AddUserService,
    private addJobService: AddJobService,
    private alertService: AppAlertService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private manageJobsService: ManageJobsService,
    private dataService: DataService,
    private _location: Location,
    private addFleetService: AddFleetService,
    // private trailerTrackingService: ManageTrailerTrackingServiceService,
  ) {
    this.jobForm = this.fb.group({
      assignedToUserId: ['', Validators.required],
      description: [''],
      dropLocationId: ['', Validators.required],
      dropSpotId: [''],
      fleetId: ['', Validators.required],
      fleetStatus: [null],
      pickupLocationId: ['', Validators.required],
      pickupSpotId: ['', Validators.required],
      priority: ['', Validators.required],
      clientId: ['', Validators.required],
      isEdit: [''],
      fleetAndHotTrailer: ['', Validators.required],
      sequenceAsn: [''],
      trailerType: [''],
      carrier: [''],
      bucket: ['']
    });

    this.fleetForm = this.fb.group({
      clientIds: ['', Validators.required],
      unitNumber: ['', Validators.required],
      carrier: ['', Validators.required],
      remarks: [''],
      type: ['', Validators.required],
      // owner: ['',Validators.required],
      // fleetStatus: [null],
      // spotId: [null],
      // locationId: ['']
    });

    this.activatedRoute.queryParams.subscribe(qparams => {
      if (qparams["id"]) {
        console.log("qparams",qparams)
        this.jobId = qparams["id"];
        this.status = qparams["status"];
        this.jobQuery = { isActive: true, size: qparams["size"], page: qparams["page"] };
        this.getJobById(this.jobId, this.jobQuery);
      }
      if (qparams["locationBack"]) {
        this.locationBack = qparams["locationBack"];
        
      }
    })

  }

  ngOnInit(): void {
    this.uniqueClientId = null;
    this.isInputTouched = false;
    this.query = { isActive: true, size: 1000, page: 0 };
    this.query1 = { isActive: true, size: 3000, page: 0 };
    this.userRoles = this.tokenService.getUserRoles();
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
      this.getClientLocations(this.query, this.tokenService.getUserClientId());
      // this.viewUsers(this.query, this.tokenService.getUserClientId());
    }

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT || role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR || role === APP_CONSTANTS.USER_ROLES.ROLE_IT || role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN)) {
      this.jobForm.controls['sequenceAsn'].setValidators([Validators.required]);
      this.isSupervisorOrClient = true;
    }else{
      this.jobForm.controls['sequenceAsn'].clearValidators();
      this.isSupervisorOrClient = false;
    }
    this.jobForm.controls['sequenceAsn'].updateValueAndValidity();

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER)) {
      this.isDriver = true;
    }

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER)) {
      this.hideButtonsIfSpotter = true;
      this.isSpotter = true;
    }
    if (this.userRoles.some(role => role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.notAdminIT = true;
    }


    this.userId = this.tokenService.getUserId();
    // if(this.userId){
    //   this.getUserById(this.userId);
    // }

    // this.getFleetList(this.query);
    this.getClientList(this.query);
    // this.getCarrier();
    
    // this.viewUsers(this.query);

    this.breadcrumbItems = [];
    if(this.locationBack){
        // let data = this.dataService.getData();
        let data = JSON.parse(localStorage.getItem('breadcrumb')!);
        if(data.breadcrumb){
          data.breadcrumb.forEach(link => {
            this.breadcrumbItems.push({
              label: link.label,
              routerLink: link.url,
              queryParams: link.queryParams
            });
          });
        }
        // if(data.data){
        //   console.log("data",data.data);

        //   this.getSpots(this.query, data.data.pickupLocation.clientId, data.data.pickupLocation.locationId, 'pickup');
        //   this.getSpots(this.query, data.data.dropLocation.clientId, data.data.dropLocation.locationId, 'drop');

        //   this.jobForm.patchValue({
        //     clientId: data.data.pickupLocation?.clientId ? data.data.pickupLocation?.clientId : '',
        //     // assignedToUserId: data.data.assignedTo.userId ? this.job.assignedTo.userId : '',
        //     // description: data.data.description ? this.job.description : '',
        //     dropLocationId: data.data.dropLocation?.locationId ? data.data.dropLocation?.locationId : '',
        //     dropSpotId: data.data.dropSpot ? data.data.dropSpot?.spotId : '',
        //     fleetId: data.data.fleet?.fleetId ? data.data.fleet?.fleetId:'',
        //     fleetStatus: data.data.fleet?.fleetStatus ? data.data.fleet?.fleetStatus : '',
        //     pickupLocationId: data.data.pickupLocation?.locationId ? data.data.pickupLocation.locationId : '',
        //     pickupSpotId: data.data.pickupSpot ? data.data.pickupSpot.spotId : '',
        //     // priority: data.data.priority ? this.job.priority : '',
        //     fleetAndHotTrailer: data.data.fleet?.fleetId ? this.checkIfHotTrailer(data.data.fleet) : '',
        //     sequenceAsn: data.data.sequenceAsn ? data.data.sequenceAsn : '',
        //     // sequenceAsn: data.data.sequenceAsn ? data.data.sequenceAsn : '',
        //   })
        // }
    }else{
      this.breadcrumbItems.push({ label: 'Spots', routerLink: '../manage-jobs' });
    }
    
    this.breadcrumbItems.push({ label: 'Add Spot' });
    
  }


  removeTextBeforeColon(text) {
    let parts = text.split(':');
    if (parts.length > 1) {
      return parts[1].trim();
    }
    return text.trim(); // Return trimmed text if ':' is not found
  }

  getJobById(id, query) {
    this.manageJobsService.viewJobs(query,this.status).subscribe(res => {
      this.job = res.list.find(job => job.jobId == id);
      console.log("job",this.job);
      if (this.job.description) {

        var description = this.removeTextBeforeColon(this.job.description);
        this.job.description = description;
      }



      this.getClientLocations(this.query, this.job.pickupLocation.clientId);
      this.viewUsers(this.query, this.job.pickupLocation.clientId);
      this.getSpots(this.query, this.job.pickupLocation.clientId, this.job.pickupLocation.locationId, 'pickup');
      this.getSpots(this.query, this.job.dropLocation.clientId, this.job.dropLocation.locationId, 'drop');
      this.getClientFleetList(this.query, this.job.pickupLocation.clientId)
      this.uniqueClientId = this.job.pickupLocation.clientId;

      this.jobForm.patchValue({
        clientId: this.job.pickupLocation.clientId ? this.job.pickupLocation.clientId : '',
        // assignedToUserId: this.job.assignedTo.userId ? this.job.assignedTo.userId : '',
        description: this.job.description ? this.job.description : '',
        dropLocationId: this.job.dropLocation.locationId ? this.job.dropLocation.locationId : '',
        dropSpotId: this.job.dropSpot ? this.job.dropSpot.spotId : '',
        fleetId: this.job.fleet.fleetId ? this.job.fleet.fleetId:'',
        fleetStatus: this.job.fleetStatus ? this.job.fleetStatus : '',
        pickupLocationId: this.job.pickupLocation.locationId ? this.job.pickupLocation.locationId : '',
        pickupSpotId: this.job.pickupSpot ? this.job.pickupSpot.spotId : '',
        priority: this.job.priority ? this.job.priority : '',
        fleetAndHotTrailer: this.job.fleet.fleetId ? this.checkIfHotTrailer(this.job.fleet) : '',
        sequenceAsn: this.job.sequenceAsn ? this.job.sequenceAsn : '',
      })

      if(this.status == "OPEN")
      {
        this.jobForm.patchValue({
          assignedToUserId: this.job.assignedTo.userId ? this.job.assignedTo.userId : ''
        })
      }
      if(this.status == "QUEUE")
        {
            this.jobForm.patchValue({
              assignedToUserId: this.job.bucket ? this.job.bucket : ''
            })
        }

      this.uniqueFleetId = this.job.fleet.fleetId ? this.job.fleet.fleetId : null;


    })
  }

  getClientList(query) {
    this.loader.show()
    this.subscription.add(
      this.manageClientsService.viewClients(query).subscribe(response => {
        this.clientList = response.list;
        if (this.notAdminIT) {
          this.jobForm.patchValue({
            clientId: this.clientList[0].clientId
          });
          this.fleetForm.patchValue({
            clientIds: [this.clientList[0].clientId]
          });
          this.getClientLocations(this.query, this.clientList[0].clientId);
          this.viewUsers(this.query, this.clientList[0].clientId);
          this.getClientFleetList(this.query1, this.clientList[0].clientId);
          this.uniqueClientId = this.clientList[0].clientId;
        }

        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  getUserById(userId) {
    this.loader.show();
    this.addUserService.getUserById(userId)
      .pipe(
        filter(res => res.clients ? res : this.loader.hide()),
        mergeMap(res =>
          combineLatest(
            [forkJoin(res['clients'].map(client => this.manageLocationsService.viewLocations(this.query, client.clientId))),
            of(res)])),
        map(([locations, res]) => {
          return locations
        })
      ).subscribe((locations: []) => {
        locations.map(loc => {
          this.clientsLocationsList.push(loc["list"])
        })
        this.clientsLocationsList = this.flatten(this.clientsLocationsList);
        this.loader.hide();
        // uwiWJZux
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });
  }

  flatten(arr) {
    return arr.reduce((flat, toFlatten) => {
      return flat.concat(Array.isArray(toFlatten) ? this.flatten(toFlatten) : toFlatten);
    }, []);
  }

  viewUsers(query: IpagedQuery, clientId?: any) {
    this.drivers = [];
    this.loader.show();
    this.subscription.add(
      this.manageUsersService.viewUsers(query, clientId).
        pipe(
          map(res => {
            let users = [];
            for (let user of res.list) {
              let obj = {
                ...user,
                fullName: user.firstName + " " + user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users => {
          this.drivers = [];
          if (this.userRoles.some(role => role == APP_CONSTANTS.USER_ROLES.ROLE_ADMIN || role == APP_CONSTANTS.USER_ROLES.ROLE_IT || role == APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR)) {
            this.drivers.push(
             { fullName: 'Bucket System Driver', userId: 'BUCKET_DRIVER' },
             { fullName: 'Bucket System Spotter', userId: 'BUCKET_SPOTTER' },
            );
           }
           if (this.userRoles.some(role => role == APP_CONSTANTS.USER_ROLES.ROLE_CLIENT)){
            this.drivers.push(
              { fullName: 'Bucket System Spotter', userId: 'BUCKET_SPOTTER' }
             );
           }

          let usersList = [];
          if(this.userType !== 'ROLE_CLIENT')
          {
            users.map(user => {
              for (let role of user["roles"]) {
  
                if (role.roleName === "DRIVER" || role.roleName === "SPOTTER" || role.roleName === "SUPERVISOR") {
                  this.drivers.push(user);
                }
              }
            })
          }
          
          if (this.userRoles.some(role => role == APP_CONSTANTS.USER_ROLES.ROLE_ADMIN || role == APP_CONSTANTS.USER_ROLES.ROLE_IT)){

            if(this.status == "OPEN")
              {
                  this.jobForm.patchValue({
                    assignedToUserId: this.job.assignedTo.userId ? this.job.assignedTo.userId : ''
                  })
              }
              if(this.status == "QUEUE")
                {
                    this.jobForm.patchValue({
                      assignedToUserId: this.job.bucket ? this.job.bucket : ''
                    })
                }
          }
         
          // this.drivers = usersList;
          if (this.isDriver || this.isSpotter) {
            this.drivers = this.drivers.filter(user => user.userId === this.userId);
          }
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  getFleetList(query: IpagedQuery) {
    this.loader.show();
    this.subscription.add(
      this.manageFleetsService.viewFleets(query)
        .pipe(
          map(fleets => {
            let fleetsArray = [];
            for (let fleet of fleets.list) {
              let obj = {
                ...fleet,
                fleetAndHotTrailer: this.checkIfHotTrailer(fleet)
              };
              fleetsArray.push(obj);
            }
            return {
              list: fleetsArray
            }

          })
        ).subscribe(response => {
          this.trailers = response.list;
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  checkIfHotTrailer(fleet) {
    if (fleet.isHotTrailer) {
      return `${fleet.unitNumber} - (Hot Trailer)`
    } else {
      return `${fleet.unitNumber}`
    }
  }

  getClientFleetList(query1: IpagedQuery, clientId?: any) {
    this.loader.show();
    this.subscription.add(
      this.manageFleetsService.viewFleets(query1, clientId)
        .pipe(
          map(fleets => {
            let fleetsArray = [];
            for (let fleet of fleets.list) {
              let obj = {
                ...fleet,
                fleetAndHotTrailer: this.checkIfHotTrailer(fleet)
              };
              fleetsArray.push(obj);
            }
            return {
              list: fleetsArray
            }

          })
        ).subscribe(response => {
          this.trailers = response.list;
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  // getClientSpots(query,clientId,locationId) {
  //   this.loader.show();
  //   this.subscription.add(
  //       this.manageSpotsService.viewSpots(query,clientId,locationId).subscribe(response=>{
  //           this.spots = response.list;
  //           this.loader.hide();
  //       })
  //   )
  // }

  getClientLocations(query, clientId) {
    this.loader.show();
    this.subscription.add(
      this.manageLocationsService.viewLocations(query, clientId).subscribe(response => {
        this.clientsLocationsList = response.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }
  /*
    onChange(event,type) {
      this.loader.show();
      let location = this.clientsLocationsList.find(loc => loc.locationId == event.value);
      this.manageSpotsService.viewSpots(this.query,location.clientId,location.locationId)
      .pipe(
        map(spots=>{
          let spotsArray = [];
          for(let spot of spots.list){
              let obj = {
                  ...spot,
                  spotAndStatus : this.spotAndFleetStatus(spot)
                };
                spotsArray.push(obj);
          }
          return {
            list : spotsArray,
            totalElements : spots.totalElements
          }
  
        })
      ).subscribe(res=>{
        type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
        this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
    })
    }
  
  */



  onChange(event, type) {

    if(type == 'pickup')
    {
      this.jobForm.patchValue({
        
        pickupSpotId:  ''
      })
    }
    if(type == 'drop')
    {
      this.jobForm.patchValue({
        
        dropSpotId:  ''
      })
    }
    this.loader.show();
    let location = this.clientsLocationsList.find(loc => loc.locationId == event.value);
    this.manageSpotsService.viewDropdownSpots(this.query, location.clientId, location.locationId)
      .pipe(
        map(spots => {
          let spotsArray = [];
          for (let spot of spots.list) {
            let obj = {
              ...spot,
              spotAndStatus: this.spotAndFleetStatus(spot)
            };
            spotsArray.push(obj);
          }
          return {
            list: spotsArray,
            totalElements: spots.totalElements
          }

        })
      ).subscribe(res => {
        type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
  }



  onSubmit() {
    if(this.jobForm.value.assignedToUserId == "BUCKET_DRIVER" || this.jobForm.value.assignedToUserId == "BUCKET_SPOTTER"){
      this.isBucket = true;
    }
    this.submitted = true;
    console.log(this.jobForm);
    
    if (this.jobForm.invalid) {
        if((this.jobForm.value.fleetId == "" || this.jobForm.value.fleetId == undefined) && (this.jobForm.value.fleetAndHotTrailer != "" && this.jobForm.value.fleetAndHotTrailer != undefined)){
          let fleets_arr = this.filteredFleets.find((x:any) => x.unitNumber == this.jobForm.value.fleetAndHotTrailer);
          if(fleets_arr == undefined){
            this.isTrailerFoundInList = true;
          }else{
            this.isTrailerFoundInList = false;
          }
          
        }else{
          this.isTrailerFoundInList = false;
        }
      this.jobForm.markAllAsTouched();
    } else {
      if (!this.uniqueFleetId) {
        this.jobForm.markAllAsTouched();
      } else {
        // this.jobForm.patchValue({
        //   fleetId: this.uniqueFleetId
        // })
      }
      
      if((this.jobForm.value.fleetId == "" || this.jobForm.value.fleetId == undefined) && this.isNewTrailer){
        this.saveNewFleet();
      }else{
        this.loader.show();
      if (this.jobId) {

        this.jobForm.patchValue({
          isEdit: true
        })
        if(this.jobForm.value.assignedToUserId == "BUCKET_DRIVER" || this.jobForm.value.assignedToUserId == "BUCKET_SPOTTER"){
          this.jobForm.patchValue({
            bucket: this.jobForm.value.assignedToUserId,
            assignedToUserId: ""
          });
        }else{
          this.jobForm.patchValue({
            bucket: "NIL"
          });
        }
        this.addJobService.updateJob(this.jobId, this.jobForm.value).subscribe(res => {
          this.loader.hide();
          this.submitted = false;
          this.alertService.alertSuccess(['Spot Updated Successfully']);
          this.isBucket = false;
          if(this.locationBack){
            this._location.back();
          }else{
            this.router.navigate(['main/manage-jobs'])
          }
          
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      } else {
        if(this.jobForm.value.assignedToUserId == "BUCKET_DRIVER" || this.jobForm.value.assignedToUserId == "BUCKET_SPOTTER"){
          this.jobForm.patchValue({
            bucket: this.jobForm.value.assignedToUserId,
            assignedToUserId: ""
          });
        }else{
          this.jobForm.patchValue({
            bucket: "NIL"
          });
        }
        this.addJobService.saveJob(this.jobForm.value).subscribe(res => {
          this.loader.hide();
          this.submitted = false;
          this.alertService.alertSuccess(['Spot Added Successfully']);
          if(this.locationBack){
            this._location.back();
          }else{
            this.router.navigate(['main/manage-jobs'])
          }
        }, (error) => {
          if(this.jobForm.value.bucket == "BUCKET_DRIVER" || this.jobForm.value.bucket == "BUCKET_SPOTTER"){
            this.jobForm.patchValue({
              assignedToUserId: this.jobForm.value.bucket,
              bucket: ""
            });
          }
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      }
    }
    }
  }

  saveNewFleet(){
    let data = {
      clientIds: [this.jobForm.value.clientId],
      unitNumber: this.jobForm.value.fleetAndHotTrailer,
      carrier: this.jobForm.value.carrier,
      type: this.jobForm.value.trailerType
    };
    this.addFleetService.saveFleet(data)
        .subscribe(res => {
          this.jobForm.patchValue({
            fleetId: res.fleetId
          })
          if (this.jobId) {

            this.jobForm.patchValue({
              isEdit: true,
              bucket: "NIL"
            })
            this.addJobService.updateJob(this.jobId, this.jobForm.value).subscribe(res => {
              this.loader.hide();
              this.submitted = false;
              this.alertService.alertSuccess(['Spot Updated Successfully']);
              if(this.locationBack){
                this._location.back();
              }else{
                this.router.navigate(['main/manage-jobs'])
              }
              
            }, (error) => {
              this.loader.hide();
              this.errorService.handleError(error, true);
            })
          } else {
            if(this.jobForm.value.assignedToUserId == "BUCKET_DRIVER" || this.jobForm.value.assignedToUserId == "BUCKET_SPOTTER"){
              this.jobForm.patchValue({
                bucket: this.jobForm.value.assignedToUserId,
                assignedToUserId: ""
              });
            }else{
              this.jobForm.patchValue({
                bucket: "NIL"
              });
            }
            this.addJobService.saveJob(this.jobForm.value).subscribe(res => {
              this.loader.hide();
              this.submitted = false;
              this.alertService.alertSuccess(['Spot Added Successfully']);
              if(this.locationBack){
                this._location.back();
              }else{
                this.router.navigate(['main/manage-jobs'])
              }
            }, (error) => {
              if(this.jobForm.value.bucket == "BUCKET_DRIVER" || this.jobForm.value.bucket == "BUCKET_SPOTTER"){
                this.jobForm.patchValue({
                  assignedToUserId: this.jobForm.value.bucket,
                  bucket: ""
                });
              }
              this.loader.hide();
              this.errorService.handleError(error, true);
            })
          }
          this.isNewTrailer = false;
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  onClientSelect(event) {
    console.log(event.value);
    this.jobForm.get('fleetId').reset(); 
    this.jobForm.get('fleetAndHotTrailer').reset(); 

    this.getClientLocations(this.query, event.value);
    this.viewUsers(this.query, event.value);
    this.getClientFleetList(this.query1, event.value);
    this.uniqueClientId = event.value;

  }

  getSpots(query, clientId, locationId, type) {
    this.loader.show();
    this.manageSpotsService.viewSpots(query, clientId, locationId)
      .pipe(
        map(spots => {
          let spotsArray = [];
          for (let spot of spots.list) {
            let obj = {
              ...spot,
              spotAndStatus: this.spotAndFleetStatus(spot)
            };
            spotsArray.push(obj);
          }
          return {
            list: spotsArray,
            totalElements: spots.totalElements
          }

        })
      ).subscribe(res => {
        type == 'pickup' ? this.pickupSpots = res.list : this.dropSpots = res.list;
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
  }

  spotAndFleetStatus(spot) {
    if (spot.status == 'EMPTY' && spot?.fleet == null) {
      return `${spot.spotName} - Empty`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Full Trailer`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Empty Trailer`
    } else if (spot.status == 'TO_BE_EMPTY') {
      return spot?.fleet == null ? `${spot.spotName} - Scheduled for Pick-Up` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Scheduled for Pick-Up`
    } else if (spot.status == 'TO_BE_OCCUPIED') {
      return spot?.fleet == null ? `${spot.spotName} - Location Reserved` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Location Reserved`
    } else {
      return `${spot.spotName}`
    }

  }

  deleteJob() {
    this.deleteJobDialog = true;
  }

  confirmDelete() {
    this.loader.show();
    this.subscription.add(
      this.addJobService.deleteJob(this.jobId).subscribe(res => {
        this.deleteJobDialog = false;
        this.alertService.alertSuccess([`Deleted Successfully`]);
        this.router.navigate(['main/manage-jobs'])
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  filterFleets(event) {
    // let filtered : any[] = [];
    // let query = event.query;

    // for(let i = 0; i < this.trailers.length; i++) {
    //     let fleet = this.trailers[i];
    //     if (fleet.fleetAndHotTrailer.toLowerCase().indexOf(query.toLowerCase()) == 0) {
    //         filtered.push(fleet);
    //     }
    // }

    // this.filteredFleets = filtered;
    console.log(event);
    console.log(this.uniqueClientId)
    if (this.uniqueClientId != null) {
      this.manageFleetsService.viewFleets(this.query, this.uniqueClientId, event.query)
        .pipe(
          map(fleets => {
            let fleetsArray = [];
            for (let fleet of fleets.list) {
              let obj = {
                ...fleet,
                fleetAndHotTrailer: this.checkIfHotTrailer(fleet)
              };
              fleetsArray.push(obj);
            }
            return {
              list: fleetsArray
            }

          })
        ).subscribe(response => {
          this.filteredFleets = response.list;
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        });

    }

  }


  filterFleets1(event) {
    console.log(event);
  }

  onFleetSelect(event) {
    
    console.log(event.fleetAndHotTrailer);
    this.jobForm.patchValue({
      fleetId: event.fleetId,
      fleetAndHotTrailer: event.fleetAndHotTrailer
    })
    this.uniqueFleetId = event.fleetId ? event.fleetId : null;
  }

  clearUniqueId(event) {
    this.uniqueFleetId = null;
  }

  onUnitNumberChange(event){
    console.log(event)
    this.jobForm.patchValue({
      fleetId: "",
    })
    this.uniqueFleetId = "";
  }

  onTrailerChange(){
    setTimeout(() => {
      this.isInputTouched = true;
      if((this.jobForm.value.fleetId == "" || this.jobForm.value.fleetId == undefined) && (this.jobForm.value.fleetAndHotTrailer != "" && this.jobForm.value.fleetAndHotTrailer != undefined)){
        this.addFleetDialog = true;
      }
    }, 200);
    
  }

  addFleet(){
    this.trailerSubmitted = true;
    if (this.fleetForm.invalid) {
      this.fleetForm.markAllAsTouched();
    } else {
      this.loader.show();
        this.addFleetService.saveFleet(this.fleetForm.value).subscribe(res => {
          this.trailerSubmitted = false;
          this.addTrailerHeader = "Confirm";
          this.loader.hide();
          this.alertService.alertSuccess([`Added Successfully`]);
          this.jobForm.patchValue({
            fleetId: res.fleetId,
            fleetAndHotTrailer: res.unitNumber
          });
          this.addFleetDialogConfirm = false;
          this.addFleetDialog = false;
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
      
    }
  }

  afterClientSelect(editFleet?) {
    // if (this.fleetForm.value.clientIds != "") {
    //   this.loader.show();
    //   this.fleetForm.value.clientIds;
    //   forkJoin(
    //     this.fleetForm.value.clientIds.map(cId =>
    //       this.manageLocationsService.viewLocations(this.query, cId).pipe(
    //         map(location => {
    //           return location.list
    //         })
    //       )
    //     )
    //   ).subscribe(res => {
    //     // flatten the multi-dimensional array
    //     this.clientsLocationsList = this.flatten(res);
    //     if (editFleet && editFleet.spot) {
    //       if (this.clientsLocationsList.some(location => location.locationId == editFleet.spot.locationId)) {
    //         this.fleetForm.patchValue({
    //           locationId: editFleet?.spot?.locationId
    //         });
    //         this.onLocationChange({ value: editFleet.spot.locationId }, editFleet)
    //       } else {
    //         this.fleetForm.patchValue({
    //           locationId: '',
    //           spotId: null
    //         });
    //         this.spotsList = [];
    //       }
    //     } else {
    //       this.fleetForm.patchValue({
    //         locationId: '',
    //         spotId: null
    //       });
    //       this.spotsList = [];
    //     }
    //     this.loader.hide();
    //   }, (error) => {
    //     this.loader.hide();
    //     this.errorService.handleError(error, true);
    //   });
    // } else {
    //   this.clientsLocationsList = [];
    //   this.spotsList = [];
    //   this.fleetForm.patchValue({
    //     locationId: '',
    //     spotId: null
    //   })
    // }
  }

  getCarrier(event) {

    console.log(event);

    this.addFleetService.getCarrier(event.query)
      .subscribe(response => {
        this.uniqueCarrierList = response;
        console.log(this.uniqueCarrierList);
        this.loader.hide();
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });

  }

  clearCarrier() {
    this.uniqueCarrierList = null;
  }

  addFleetConfirm(){
    this.addFleetDialogConfirm = true;
    this.addTrailerHeader = "Add Asset";
    this.fleetForm.patchValue({
      unitNumber: this.jobForm.value.fleetAndHotTrailer,
      clientIds: [this.jobForm.value.clientId],
      type: "TRAILER"
    })
  }

  cancelAddFleet(){
    this.trailerSubmitted = false;
    this.addTrailerHeader = "Confirm";
    this.fleetForm.reset();
    this.addFleetDialogConfirm = false;
    this.addFleetDialog = false;
  }

  showNewTrailerFields(){
    if (this.isNewTrailer == true) {
      this.isNewTrailer = false;
      this.jobForm.controls['fleetId'].setValidators([Validators.required]);
      this.jobForm.controls['fleetId'].updateValueAndValidity();
      this.jobForm.get('fleetId').reset(); 
      this.jobForm.get('fleetAndHotTrailer').reset(); 
    } else {
      this.isNewTrailer = true;
      this.jobForm.controls['fleetId'].setValidators([]);
      this.jobForm.controls['fleetId'].updateValueAndValidity();
      this.jobForm.get('fleetId').reset(); 
    }
    this.jobForm.patchValue({
      trailerType: "TRAILER"
    });
  }

}
