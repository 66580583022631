<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Clients</h5>
                    </div>
                </ng-template>
                
                <ng-template pTemplate="right">
                    <button *ngIf="hideButtons == false && hideButtonsIfSupervisor == false" pButton pRipple label="New" icon="pi pi-plus" class="p-button-success mr-2" (click)="routeToAddClient()"></button>
                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button> -->

                       <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()" styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>
                        
                </ng-template>
            
            </p-toolbar>

            <p-table #dt [value]="clients" [loading]="loading" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [globalFilterFields]="['clientName']" [rows]="10" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span></span>
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th pSortableColumn="clientName">Name</th>
                        <th pSortableColumn="contactEmail">Email</th>
                        <th pSortableColumn="contactPhone">Phone</th>
                        <th pSortableColumn="address">Address</th>
                        <th pSortableColumn="flag">Feature</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-client>
                    <tr>
                        <td style="min-width: 255px;">
                            <button pButton pRipple icon="pi pi-eye" pTooltip="View" class="p-button-rounded p-button-success mr-2" (click)="routeToDetails(client.clientId)"></button>
                            <button pButton pRipple icon="pi pi-map-marker" pTooltip="Locations" class="p-button-rounded p-button-help mr-2" (click)="routeToLocation(client.clientId,client.clientName)"></button>
                            <button *ngIf="hideButtons == false && hideButtonsIfSupervisor == false" pButton pRipple icon="pi pi-pencil" pTooltip="Edit" class="p-button-rounded p-button-warning mr-2" (click)="routeToEdit(client.clientId)"></button>
                            <button *ngIf="hideButtons == false && hideButtonsIfSupervisor == false" pButton pRipple icon="pi pi-flag" pTooltip="Edit" class="p-button-rounded p-button-secondary mr-2" (click)="showConfig(client)"></button>
                            <button *ngIf="hideButtons == false && hideButtonsIfSupervisor == false" pButton pRipple icon="pi pi-trash" pTooltip="Delete/Deactivate" class="p-button-rounded p-button-danger" (click)="deleteClientConfirmation(client)"></button>
                        </td>
                        <td><span class="p-column-title">Name</span>
                            {{client.clientName}}
                        </td>
                        <td><span class="p-column-title">Email</span>
                            {{client.contactEmail}}
                        </td>
                        <td><span class="p-column-title">Phone</span>
                            {{client.contactPhone}}
                        </td>
                        <td>
                            <span class="p-column-title">Address</span>
                            {{client.street}}, 
                            {{client.city}}, 
                            {{client.state}}, 
                            {{client.zip}}
                        </td>
                        <td style="min-width: 220px;">
                            <span class="p-column-title">Feature</span>
                            
                           <div *ngIf="client.bol == true">BoL</div>
                           <!-- <div *ngIf="client.dvir == true">DVIR</div> -->
                           <div *ngIf="client.accountDeactivation == true">Inactive Account</div>
                           <div *ngIf="client.trailerAudit == true">Trailer Audit</div>
                           <div *ngIf="client.jobReAssign == true">Bucket System Fallback Timer</div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">No Client found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="10" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
        </div>

        <p-dialog [(visible)]="deleteClientDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span *ngIf="client">Are you sure you want to delete/deactivate <b>{{client.clientName}}</b>?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteClientDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete(client.clientId)"></button>
            </ng-template>
        </p-dialog>

        <p-dialog [(visible)]="clientConfigModal" [style]="{width: '450px', minHeight:'550px'}" [modal]="true" (onHide)="hideDialog1()" class="p-fluid">
           <ng-template pTemplate="header">
            <h4>{{selectedClient.clientName}}&nbsp;&nbsp;Configurations</h4>
           </ng-template>
            <ng-template pTemplate="content">    
               <div class="field" style="display: flex;flex-direction: row;margin-bottom: 20px;margin-top: 20px;">
                <h5 style="margin-top: 10px;margin-right: 140px;">BoL</h5>   
                    <p-selectButton [options]="stateOptions" [(ngModel)]="selectedClient.bol" optionLabel="label" optionValue="value" (ngModelChange)="onSelectChange('bol',$event)" class="ng-invalid ng-dirty"></p-selectButton>
                </div>
                <div class="field" style="display: flex;flex-direction: row;">
                    <h5 style="margin-top: 10px;margin-right:70px;">Trailer Audit</h5>
                       <p-selectButton [options]="stateOptions" [(ngModel)]="selectedClient.trailerAudit" optionLabel="label" optionValue="value" (ngModelChange)="onSelectChange('trailerAudit',$event)" class="ng-invalid ng-dirty"></p-selectButton>
                </div> 
               <!-- <div class="field" style="display: flex;flex-direction: row;">
               <h5 style="margin-top: 10px;margin-right:130px;">DVIR</h5>
                  <p-selectButton [options]="stateOptions" [(ngModel)]="selectedClient.dvir" optionLabel="label" optionValue="value" (ngModelChange)="onSelectChange('dvir',$event)" class="ng-invalid ng-dirty"></p-selectButton>
               </div>   -->
               <div *ngIf="hideFlagAccountDeletion === false" class="field" style="display: flex;flex-direction: row; margin-top: 22px;">
                <h5 style="margin-right: -30px;width: 200px;">Inactive Account Deletion</h5>
                   <p-selectButton [options]="stateOptions" [(ngModel)]="selectedClient.accountDeactivation" optionLabel="label" optionValue="value" (ngModelChange)="onSelectChange('accountDeactivation',$event)" class="ng-invalid ng-dirty"></p-selectButton>
                </div> 
                <!-- <div class="field" style="display: flex;flex-direction: row;">
                    <h5 style="margin-right:-10px;width: 180px;">Bucket System Fallback Timer</h5>
                       <p-selectButton [options]="stateOptions" [(ngModel)]="selectedClient.jobReAssign" optionLabel="label" optionValue="value" (ngModelChange)="onSelectChange('jobReAssign',$event)" class="ng-invalid ng-dirty"></p-selectButton>
                </div>  -->
                
           </ng-template>
           <ng-template pTemplate="footer">
            <div style="text-align: left;">
                <button pButton pRipple label="Return" icon="pi pi-arrow-left" class="p-button-text" (click)="hideDialog1()" type="button" ></button>
            </div>
           </ng-template>
       </p-dialog>
    </div>
</div>
