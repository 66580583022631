import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { faHome, faBuilding, faTruck, faUser, faComment } from '@fortawesome/free-solid-svg-icons';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { DashboardService } from './dashboard.service';
import * as moment from 'moment';
import { combineLatest } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { TokenService } from 'src/app/security/token.service';
import { Router } from '@angular/router';
import { ErrorService } from 'src/app/error-handling/error.service';
import { WebSocketService } from 'src/app/web-socket.service';
 
@Component({
    templateUrl: './dashboard.component.html',
    styleUrls : ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    items: MenuItem[];

    products: Product[];

    selectedClient;
    
    faBuilding = faBuilding;
    faTruck = faTruck;
    pieData: { labels: string[]; datasets: { data: number[]; backgroundColor: string[]; hoverBackgroundColor: string[]; }[]; };
    pieOptions: { responsive:boolean,plugins: { legend: { position:string, labels: { color: string; }; }; }; };
    clients:[] = [];
    days:any[]=[{"label":"24 hours","value":1},{"label":"7 days","value":7},{"label":"30 days","value":30},{"label":"Year To Date(YTD)","value":365}];
    daysQuery:IpagedQuery;
    daysLabel:string;
    clientQuery:IpagedQuery;
    todayQuery:IpagedQuery;
    monthQuery:IpagedQuery;
    sevenDaysQuery:IpagedQuery;
    oneYearQuery:IpagedQuery;
    timePeriod:string;
    unreadMessage:boolean = false;
    generalStatistics = {
        totalClients: 0,
        totalTrailers: 0,
        totalTrucks: 0,
        totalUsers: 0
    }
    messageStatistics = {
        newCount:0,
        readCount:0
    }
    jobStatistics = {
        activeJobs:0,
        pendingJobs:0,
        completedJobsOneMonth:0,
        completedJobsToday:0
    }
    userRoles: any;
    userType: string;
    m:number;
    n:number;
    
  
    private webSocket: WebSocket;
    constructor(private loader: AppLoaderService,
        private manageClientsService:ManageClientsService,
        private dashboardService:DashboardService,
        private tokenService:TokenService,
        private router:Router,
        private errorService:ErrorService,
        private webSocketService:WebSocketService) 
        {
            
            this.connectWebSocket();
        }
    ngOnDestroy() {

        if (this.webSocket) {
            this.webSocket.onclose = null; 
            this.webSocket.close();
        }
    }

    ngOnInit() {
        
        this.m = 0;
        this.n = 0;
        this.timePeriod = "24h";
        this.userRoles = this.tokenService.getUserRoles();
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER || role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER || role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD)){
            this.router.navigate(['/main/manage-clients']);
        }
        else
        {

            if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
                this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
            }
            let nowTime =  moment().format('yyyy-MM-DD HH:mm');
            let twentyFourHours =  moment().add(-24,'hours').format('yyyy-MM-DD HH:mm');
            let today = moment().startOf('day').format('yyyy-MM-DD HH:mm');
            let todayCurrentTime = moment().format('yyyy-MM-DD HH:mm');
            let tomorrow = moment().add(1, 'days').startOf('day').format('yyyy-MM-DD HH:mm');
            let yesterday = moment().add(-1, 'days').startOf('day').format('yyyy-MM-DD HH:mm');
            let month = moment().add(-30, 'days').format('yyyy-MM-DD HH:mm');
            let sevenDays = moment().add(-7, 'days').format('yyyy-MM-DD HH:mm');
           // let oneYear =moment().add(-365, 'days').format('yyyy-MM-DD HH:mm');
            let januaryFirst = moment().startOf('year').format('YYYY-MM-DD HH:mm');
            this.todayQuery = {
                fromDateTime:twentyFourHours,
                toDateTime:nowTime
            }
            this.monthQuery = {
                fromDateTime:month,
                toDateTime:todayCurrentTime
            }
            this.sevenDaysQuery = {
                fromDateTime:sevenDays,
                toDateTime:todayCurrentTime
            }
            this.oneYearQuery = {
                fromDateTime:januaryFirst,
                toDateTime:todayCurrentTime
            }
            
            this.clientQuery = {isActive:true,size:1000,page:0};
            this.getAllStatistics();
            this.getClientList(this.clientQuery);
              
            this.items = [
                {label: 'Add New', icon: 'pi pi-fw pi-plus'},
                {label: 'Remove', icon: 'pi pi-fw pi-minus'}
            ];
    
            this.pieData = {
                labels: ['0 In-Transit Jobs', '0 Open Jobs', '0 Completed Jobs'],
                datasets: [
                    {
                        data: [0, 0, 0],
                        backgroundColor: [
                            "#FFAA00",
                            "#ff0000",
                            "#0C7B00"
                        ],
                        hoverBackgroundColor: [
                            "#FFAA00",
                            "#ff0000",
                            "#0C7B00"
                        ]
                    }
                ]
            };
    
            this.pieOptions = {
                responsive: true,
                plugins: {
                    legend: {
                        position:'left',
                        
                        //color: textColor,
                        labels: {
                            color: '#A0A7B5',
                            
                        }
                    }
                }
            };
          
          



        }
       
      
    }

    getMessageStatics()
    {
      this.dashboardService.getMessagesCount(this.todayQuery).subscribe(response=>{
             this.messageStatistics = response;
             if(this.messageStatistics.newCount != 0)
             {
                this.unreadMessage= true;
             }
             
             this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
        }
      )
    }

    getAllStatistics(){
        this.loader.show();
        combineLatest([
            this.dashboardService.getGeneralStatistics(),
            this.dashboardService.getMessagesCount(this.todayQuery),
            this.dashboardService.getJobsCount(),
            this.dashboardService.getJobsCount("24h"),
            this.dashboardService.getJobsCount("30d"),
            this.dashboardService.getJobsCount("7d"),
        ]).subscribe(res=>{
            this.generalStatistics = res[0];
            this.messageStatistics = res[1];
            this.jobStatistics.activeJobs = res[2].activeCount;
            this.jobStatistics.pendingJobs = res[2].pendingCount;
            this.jobStatistics.completedJobsToday = res[3].completedCount;
            this.jobStatistics.completedJobsOneMonth = res[4].completedCount;
            this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
            if(this.messageStatistics.newCount != 0)
            {
                this.unreadMessage = true;
            }
            this.pieData = {
                labels: [`${res[3].activeCount} In-Transit`, `${res[3].pendingCount} Open`, `${res[3].completedCount} Completed`],
                datasets: [
                    {
                        data: [res[3].activeCount, res[3].pendingCount, res[3].completedCount],
                        backgroundColor: [
                            "#FFAA00",
                            "#ff0000",
                            "#0C7B00"
                        ],
                        hoverBackgroundColor: [
                            "#FFAA00",
                            "#ff0000",
                            "#0C7B00"
                        ]
                    }
                ]
            };
            this.daysQuery = this.todayQuery;
            this.daysLabel = "(Last 24 Hours)";
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
    }

    // getGeneralStatistics() {
    //     this.loader.show();
    //     this.dashboardService.getGeneralStatistics().subscribe(res=>{
    //         this.generalStatistics = res;
    //     })
    //     this.loader.hide();
    // }

    // getMessagesCount(query) {
    //     this.loader.show();
    //     this.dashboardService.getMessagesCount(query).subscribe(res=>{
    //         this.messageStatistics = res;
    //         this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
    //     })
    //     this.loader.hide();
    // }

    getClientList(query) {
        this.loader.show();
        this.manageClientsService.viewClients(query).subscribe(response=>{
            this.clients = response.list;
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
    }

    getClientJobs(event){
        console.log(event);
        this.selectedClient = event.value;
        this.loader.show();
            this.dashboardService.getJobsCount(this.timePeriod,this.selectedClient).subscribe(res=>{
                this.pieData = {
                    labels: [`${res.activeCount} In-Transit`, `${res.pendingCount} Open`, `${res.completedCount} Completed`],
                    datasets: [
                        {
                            data: [res.activeCount, res.pendingCount, res.completedCount],
                            backgroundColor: [
                                "#FFAA00",
                                "#ff0000",
                                "#0C7B00"
                            ],
                            hoverBackgroundColor: [
                                "#FFAA00",
                                "#ff0000",
                                "#0C7B00"
                            ]
                        }
                    ]
                };
                this.loader.hide();
            },(error) => {
                this.loader.hide();
                this.errorService.handleError(error, true);
            })  
        
        
    }


    routeToManageJobs(filter){
        this.router.navigate(['/main/manage-jobs'],{queryParams:{filter}});
    }

    selectDays(event:any)
    {
        switch(event.value)
        {
            case 1:
                this.timePeriod = "24h";
                this.daysLabel = "(Last 24 Hours)";
            break;
            
            case 7:
                //this.daysQuery = this.sevenDaysQuery;
                this.timePeriod = "7d";
                this.daysLabel = "(Last 7 Days)";
            break;

            case 30:
                // this.daysQuery = this.monthQuery;
                this.timePeriod = "30d";
                this.daysLabel = "(Last 30 Days)";
            break;

            case 365:
                // this.daysQuery = this.oneYearQuery;
                this.timePeriod = "yearToDate"
                this.daysLabel = "(Year to Date)";
            break;
        }
        this.loader.show();
        this.dashboardService.getJobsCount(this.timePeriod,this.selectedClient).subscribe(res=>{
            this.pieData = {
                labels: [`${res.activeCount} In-Transit`, `${res.pendingCount} Open`, `${res.completedCount} Completed`],
                datasets: [
                    {
                        data: [res.activeCount, res.pendingCount, res.completedCount],
                        backgroundColor: [
                            "#FFAA00",
                            "#ff0000",
                            "#0C7B00"
                        ],
                        hoverBackgroundColor: [
                            "#FFAA00",
                            "#ff0000",
                            "#0C7B00"
                        ]
                    }
                ]
            };
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })  
    }

    private connectWebSocket() {
        this.webSocket = new WebSocket(APP_CONSTANTS.SOCKET_URL);
      
        this.webSocket.onmessage = (event) => {
          this.m++;
          console.log("event:", event);
          
            setTimeout(() => {
              this.n++;
              if(this.m == this.n) {
    
                this.getMessageStatics();
                
                }
            }, 2000);
          
        };
      
        this.webSocket.onclose = () => {
          console.log("reconnect...");
          this.connectWebSocket(); 
        };
      
        this.webSocket.onerror = (error) => {
          console.log("WebSocket error:", error);
          this.webSocket.close(); // Close and trigger reconnect
        };
      }

}
