import { Component, OnInit } from '@angular/core';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { TokenService } from 'src/app/security/token.service';
import { ManageTrailerTrackingServiceService } from './manage-trailer-tracking-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { AddFleetService } from '../add-fleet/add-fleet.service';

@Component({
  selector: 'app-manage-trailer-tracking',
  templateUrl: './manage-trailer-tracking.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['./manage-trailer-tracking.component.scss']
})
export class ManageTrailerTrackingComponent implements OnInit {

  breadcrumbItems: MenuItem[] = [];
  activeTab: number = 0;
  loading: boolean = false;
  totalRecords: any;
  query: IpagedQuery;
  cols: any[];
  locationList: any[] = [];
  clientId;
  carrierList: string[] = [];
  supplierList: any[] = [];
  newSupplier: boolean = false;
  supplierForm: FormGroup;
  carrierForm: FormGroup;
  newCarrier: boolean = false;
  deleteDialog: boolean = false;
  supplierId: any;
  deleteCarrierDialog: boolean = false;
  carrierId: any;
  userRoles: any;
  userType: any;

  constructor(
    private manageLocationsService:ManageLocationsService,
    private loader:AppLoaderService,
    private alertService:AppAlertService,
    private errorService:ErrorService,
    private tokenService:TokenService,
    private trailerTrackingService: ManageTrailerTrackingServiceService,
    private fb:FormBuilder,
    private addFleetService: AddFleetService,
  ) { }

  ngOnInit(): void {

    this.breadcrumbItems.push({ label: 'Trailer Tracking' });
    this.query = { isActive: true, size: 50, page: 0 };
    this.clientId = this.tokenService.getUserClientId();
    // if(this.clientId){
    //   this.getClientLocations(this.query, this.clientId);
    // }

    this.userRoles = this.tokenService.getUserRoles();

    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_GUARD;
    }
    
    this.getSuppliers(this.query);
    this.supplierForm = this.fb.group({
      editId: [''],
      supplier: ['',Validators.required]
    });
    this.carrierForm = this.fb.group({
      editId: [''],
      carrier: ['',Validators.required]
    });
  }

  handleTabChange(event) {
    this.query.page = 0;
    if (event.index == 0) {
    //   this.getClientLocations(this.query, this.clientId);
    // } else if(event.index == 1){
      this.getSuppliers(this.query);
    }else{
      this.getCarriers(this.query);
    }
  }

  paginate(event) {
    this.query.page = event.page;
    if (this.activeTab == 0) {
    //   this.getClientLocations(this.query, this.clientId);
    // } else if(this.activeTab == 1){
      this.getSuppliers(this.query);
    }else{
      this.getCarriers(this.query);
    }

  }

  getClientLocations(query,clientId) {
    this.loader.show();
        this.manageLocationsService.viewLocations(query,clientId).subscribe(response=>{
            this.locationList = response.list;
            this.totalRecords = response.totalElements;
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        });
  }

  getSuppliers(query) {
    this.loader.show();
        this.trailerTrackingService.getSuppliers(query).subscribe(response=>{
            this.supplierList = response.list;
            this.totalRecords = response.totalElements;
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
    });
  }

  getCarriers(query) {
    this.loader.show();
    this.trailerTrackingService.getCarriersPage(query)
      .subscribe(response => {
            this.carrierList = response.content;
            this.totalRecords = response.totalElements;
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
      });
  }

  hideDialogSupplier(){
    this.supplierForm.reset();
    this.newSupplier = false;
  }

  createSupplier(){
    
    if (this.supplierForm.invalid) {
      this.supplierForm.markAllAsTouched();
    } else {
      this.loader.show();
    if(this.supplierForm.value.editId != null && this.supplierForm.value.editId != ""){
      this.trailerTrackingService.editSupplier(this.supplierForm.value, this.supplierForm.value.editId).subscribe(response=>{
        this.alertService.alertSuccess(['Supplier updated successfully']);
        this.getSuppliers(this.query);
        this.newSupplier = false;
        this.supplierForm.reset();
        this.loader.hide();
      },(error) => {
          this.errorService.handleError(error, true);
          this.loader.hide();
          
      });
    }else{
      this.trailerTrackingService.createSupplier(this.supplierForm.value).subscribe(response=>{
        this.alertService.alertSuccess(['Supplier added successfully']);
        this.getSuppliers(this.query);
        this.newSupplier = false;
        this.supplierForm.reset();
        this.loader.hide();
      },(error) => {
          this.errorService.handleError(error, true);
          this.loader.hide();
          
      });
    }
  }
  }

  // createCarrier(){
    
  //   if (this.carrierForm.invalid) {
  //     this.carrierForm.markAllAsTouched();
  //   } else {
  //     this.loader.show();
  //   if(this.carrierForm.value.editId != null && this.carrierForm.value.editId != ""){
  //     this.trailerTrackingService.editCarrier(this.carrierForm.value, this.carrierForm.value.editId).subscribe(response=>{
  //       this.alertService.alertSuccess(['Carrier updated successfully']);
  //       this.getCarriers(this.query);
  //       this.newCarrier = false;
  //       this.carrierForm.reset();
  //       this.loader.hide();
  //     },(error) => {
  //         this.errorService.handleError(error, true);
  //         this.loader.hide();
          
  //     });
  //   }else{
  //     this.trailerTrackingService.createCarrier(this.carrierForm.value).subscribe(response=>{
  //       this.alertService.alertSuccess(['Carrier added successfully']);
  //       this.getCarriers(this.query);
  //       this.newCarrier = false;
  //       this.carrierForm.reset();
  //       this.loader.hide();
  //     },(error) => {
  //         this.errorService.handleError(error, true);
  //         this.loader.hide();
          
  //     });
  //   }
  // }
    
  // }

  hideDialogCarrier(){
    this.carrierForm.reset();
    this.newCarrier = false;
  }

  createNew(){
    if(this.activeTab == 0){
      this.newSupplier = true;
    }else {
      this.newCarrier = true;
    }
  }

  editSupplier(item: any){
    this.newSupplier = true;
    this.supplierForm.patchValue({
      supplier: item.supplier,
      editId: item.supplierId
    });
  }

  deleteSupplier(supplier:any){
    this.deleteDialog = true;
    this.supplierId = supplier.supplierId
  }

  confirmDelete(){
    this.trailerTrackingService.deleteSupplier(this.supplierId).subscribe(response=>{
      this.alertService.alertSuccess(['Supplier deleted successfully']);
      this.getSuppliers(this.query);
      this.deleteDialog = false;
      this.supplierId = null;
      this.loader.hide();
    },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
        
    });
  }

  // editCarrier(item: any){
  //   this.newCarrier = true;
  //   this.carrierForm.patchValue({
  //     carrier: item.carrier,
  //     editId: item.carrierId
  //   });
  // }

  // deleteCarrier(carrier:any){
  //   this.deleteCarrierDialog = true;
  //   this.carrierId = carrier.carrierId
  // }

  // confirmCarrierDelete(){
  //   this.trailerTrackingService.deleteCarrier(this.carrierId).subscribe(response=>{
  //     this.alertService.alertSuccess(['Carrier deleted successfully']);
  //     this.getCarriers(this.query);
  //     this.deleteCarrierDialog = false;
  //     this.carrierId = null;
  //     this.loader.hide();
  //   },(error) => {
  //       this.loader.hide();
  //       this.errorService.handleError(error, true);
        
  //   });
  // }

  // deleteCancelCarrier(){
  //   this.deleteCarrierDialog = false;
  //   this.carrierId = null;
  // }

  deleteCancelSupplier(){
    this.deleteDialog = false;
    this.supplierId = null;
  }

}
