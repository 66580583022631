<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <h5 class="m-0">Asset Inventory</h5>
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="userType == 'ROLE_SUPERVISOR' || userType == 'ROLE_ADMIN' || userType == 'ROLE_IT'" pButton pRipple
                        label="New Spot" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="routeToAddJob()"></button>
                    <button
                        *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSupervisor === false && hideButtonsIfSpotter === false"
                        pButton pRipple label="Assign" icon="pi pi-plus" class="p-button-secondary mr-2"
                        (click)="assignFleetModal = true"></button>
                    <button *ngIf="hideButtonsIfDriver !== true" pButton pRipple
                        label="New Asset" icon="pi pi-plus" class="p-button-success mr-2"
                        (click)="routeToAddFleet()"></button>
                    <p-splitButton label="Export" [model]="items" (onClick)="exportExcel()"
                        styleClass="p-button-raised p-button-help mr-2 "></p-splitButton>

                    <!-- <button pButton pRipple label="Export" icon="pi pi-upload" class="p-button-help" (click)="exportExcel()"></button>
                    -->
                </ng-template>
            </p-toolbar>

            <p-toolbar styleClass="mb-4">
                <div>
                    <p class="mb-2"><span class="font-medium text-500">Full :</span> {{tallyObj?.totalFull}}</p>
                    <p class="mb-2"><span class="font-medium text-500">Empty :</span> {{tallyObj?.totalEmpty}}</p>
                </div>

                <ng-template pTemplate="right">
                    <button
                        *ngIf="hideButtonsIfDriver !== true && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false"
                        pButton pRipple class="p-button-success mr-2" (click)="mergeCarrier()">Merge Carrier</button>
                    <button
                        *ngIf="hideButtonsIfDriver !== true && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false"
                        pButton pRipple class="p-button-success mr-2" (click)="showCheckbox()"
                        [disabled]="buttonDisable">{{label}}</button>
                    <button
                        *ngIf="hideButtonsIfDriver !== true && userType !== 'ROLE_CLIENT' && hideButtonsIfGuard === false && hideButtonsIfSpotter === false"
                        pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-secondary mr-2"
                        (click)="cancel()" [disabled]="cancelButtonDisable"></button>

                </ng-template>

            </p-toolbar>




            <!-- <div class="card"> -->
            <!-- </div> -->
            <p-table #dt [value]="fleetList"
                [globalFilterFields]="['carrier','unitNumber','owner','type','fleetStatus']" [loading]="loading"
                styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true"
                [columns]="cols" [rows]="10" [rowHover]="true" dataKey="id">
                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <span>
                            <p-dropdown class="mr-2" *ngIf="userType !== 'ROLE_CLIENT'" [showClear]="true" placeholder="Select Client" [options]="clientList"
                                (onChange)="filterFleetsByClient($event)" optionLabel="clientName" optionValue="clientId"></p-dropdown>
                            <p-dropdown [options]="dropdownStatus" placeholder="Select Trailer Status" optionLabel="name" optionValue="code"
                                (onChange)="filterFleetsByStatus($event)"></p-dropdown>
                            <p-dropdown class="ml-2" *ngIf="userType == 'ROLE_CLIENT' || userType == 'ROLE_SUPERVISOR' || userType == 'ROLE_ADMIN' || userType == 'ROLE_IT'" [showClear]="true" [options]="trailerStatus" placeholder="Select Asset Status" optionLabel="name" optionValue="code"
                                (onChange)="selectTrailerStatus($event)"></p-dropdown>
                         
                            <!-- <i (click)="clearFilter()" class="pi pi-refresh ml-2"></i> -->
                        </span>


                        <!--  
                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                           <i class="pi pi-search"></i>
                            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
                           </span>
                        -->

                        <span class="block mt-2 md:mt-0 p-input-icon-left">
                            <input pInputText type="text" [(ngModel)]="searchboxValue" placeholder="Search Unit Number"
                                (input)="search()" />
                        </span>


                        <!--   <div class="input-group">
                            <mdb-form-control>
                              <input #input mdbInput type="search" id="form1" class="form-control" />
                              <label mdbLabel class="form-label" for="form1">Search</label>
                            </mdb-form-control>
                            <button type="button" class="btn btn-primary" (click)="search(input.value)">
                              <i class="pi pi-search"></i>
                            </button>
                          </div>
                        -->



                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <!-- <th pSortableColumn="client">Client</th> -->
                        <th pSortableColumn="type">Type</th>
                        <!-- <th pSortableColumn="owner">Owner</th> -->
                        <th pSortableColumn="unitNumber">Unit Number</th>
                        <th pSortableColumn="carrier">Carrier</th>
                        <!-- <th pSortableColumn="plateNumber">Plate Number</th> -->
                        <th pSortableColumn="trailerStatus">
                            <div
                                style="display: flex;flex-direction: row;justify-content: space-evenly;position: relative; ">
                                Status
                                <div class="flex justify-content-center">
                                    <p-overlayPanel #op>

                                        <ng-template pTemplate="content" style="height: 2000px;">
                                            <h4>Trailer status</h4>
                                            <p-dropdown placeholder="select status" [showClear]="true"
                                                [(ngModel)]="selectedStatus" [options]="trailerStatus"
                                                optionLabel="name" optionValue="code" (onChange)="selectTrailerStatus($event,op)">
                                            </p-dropdown>
                                        </ng-template>


                                    </p-overlayPanel>
                                    <i class="pi pi-filter" (click)="op.toggle($event)"></i>
                                </div>

                                <!-- <p-columnFilter type="text" [matchModeOptions]="matchModeOptions" [matchMode]="'custom-equals'"></p-columnFilter> -->
                            </div>

                        </th>
                        <th pSortableColumn="spotName">Location</th>
                        <th pSortableColumn="notes">Notes</th>
                        <th pSortableColumn="createdBy">Created By</th>
                        <!-- <th pSortableColumn="year">Year </th>
                        <th pSortableColumn="make">Make </th>
                        <th pSortableColumn="model">Model </th> -->
                        <!-- <th pSortableColumn="companyAssociated">Company Associated</th> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-fleet>
                    <tr>
                        <td>
                            <p-checkbox #checkboxes id="isCheckBox" value="fleet.unitNumber"
                                [style.display]="checkboxDisplay" (onChange)="handleChange1($event,fleet)"></p-checkbox>

                            <button
                                *ngIf="hideButtonsIfDriver !== true && hideButtonsIfSpotter === false && editRefreshButtonVisibility == true"
                                pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                class="p-button-rounded p-button-warning mr-2"
                                (click)="routeToEditFleet(fleet.fleetId)"></button>
                            <button
                                *ngIf="userType !== 'ROLE_CLIENT' && hideButtonsIfDriver !== true && hideButtonsIfSpotter === false && query.isActive == false && editRefreshButtonVisibility == true"
                                pButton pRipple icon="pi pi-refresh" pTooltip="Activate"
                                class="p-button-rounded p-button-success mr-2"
                                (click)="activateFleet(fleet.fleetId)"></button>
                            <!-- <button pButton pRipple icon="pi pi-trash" pTooltip="Delete/Deactivate" class="p-button-rounded p-button-danger mt-2" (click)="deleteProduct(product)"></button> -->
                        </td>
                        <!-- <td>
                            <span class="p-column-title">Client</span>
                            {{fleet.client}}
                        </td> -->
                        <td>
                            <span class="p-column-title">Type</span>
                            {{fleet.type}}
                        </td>
                        <!-- <td>
                            <span class="p-column-title">Owner</span>
                            {{fleet.owner ? fleet.owner : '-'}}
                        </td> -->
                        <td>
                            <span class="p-column-title">Unit Number</span>
                            {{fleet.unitNumber}}
                        </td>
                        <td>
                            <span class="p-column-title">Carrier</span>
                            {{fleet.carrier}}
                        </td>
                        <!-- <td>
                            <span class="p-column-title">Plate Number</span>
                            {{fleet.plateNumber}}
                        </td> -->
                        <td>
                            <span class="p-column-title">Status</span>
                            {{fleet.fleetStatus && fleet.fleetStatus=='FULL' ? 'LOADED' : '' }}
                            {{fleet.fleetStatus && fleet.fleetStatus=='EMPTY' ? 'EMPTY' : '' }}
                        </td>
                        <td>
                            <span class="p-column-title">Location</span>
                            {{fleet.spot? fleet.spot?.locationName: '' }}
                            <br>
                            {{fleet.spot? fleet.spot?.spotName : '-' }}
                        </td>
                        <td>
                            <span class="p-column-title">Notes</span>
                            {{fleet.remarks ? fleet.remarks : '-' }}
                        </td>
                        <td>
                            <span class="p-column-title">Notes</span>
                            {{fleet.audit ? fleet.audit.createdBy.firstName : '-' }}
                            {{fleet.audit ? fleet.audit.createdBy.lastName : '-' }}
                        </td>
                        <!-- <td>
                            <span class="p-column-title">Year</span>
                            {{fleet.year}}
                        </td>
                        <td>
                            <span class="p-column-title">Make</span>
                            {{fleet.make}}
                        </td>
                        <td>
                            <span class="p-column-title">Model</span>
                            {{fleet.model}}
                        </td> -->
                        <!-- <td><span class="p-column-title">Company Associated</span>
                            {{fleet.companyAssociated}}
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="7">Truck-Trailer-Container not found.</td>
                    </tr>
                </ng-template>
            </p-table>
            <p-paginator [rows]="50" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"
                (onPageChange)="paginate($event)" #paginator></p-paginator>
        </div>

        <p-dialog [(visible)]="assignFleetModal" [style]="{width: '450px', minHeight:'550px'}" header="Assign"
            [modal]="true" class="p-fluid">
            <ng-template pTemplate="content">
                <form [formGroup]="assignTrailerForm">
                    <div *ngIf="userType !== 'ROLE_CLIENT'" class="field">
                        <label htmlFor="client">Client<span class="text-danger">*</span></label>
                        <p-dropdown [options]="clientList" placeholder="Select Client" formControlName="clientId"
                            optionLabel="clientName" optionValue="clientId"></p-dropdown>
                        <small class="text-danger" *ngIf="
                    assignTrailerForm.controls.clientId.touched &&
                    assignTrailerForm.controls.clientId.invalid
                    ">Client is required</small>
                    </div>

                    <div class="field">
                        <label for="status">Trailer/Truck<span class="text-danger">*</span></label>
                        <p-multiSelect [options]="fleetList" placeholder="Select Trailer" formControlName="fleetIds"
                            optionLabel="unitNumber" optionValue="fleetId"></p-multiSelect>
                        <small class="text-danger" *ngIf="
                    assignTrailerForm.controls.fleetIds.touched &&
                assignTrailerForm.controls.fleetIds.invalid">Trailer is required</small>
                    </div>
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                    (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                    (click)="assignFleetToClient()" type="button"></button>
            </ng-template>
        </p-dialog>


        <p-dialog [(visible)]="assignUnitNumberModal" [style]="{width: '500px', minHeight:'550px'}"
            header="Trailer Merge" [modal]="true" (onHide)="hideDialog1()" class="p-fluid">
            <ng-template pTemplate="content">
                <div>
                    <h5>Selected trailers :-</h5>
                </div>
                <div class="unit-number-container">
                    <div *ngFor="let unitNumber of unitNumberList; let last = last; let i = index">
                        <ng-container *ngIf="!last">
                            <h5>{{ unitNumber }}{{i === unitNumberList.length-2 ? '' : ',' }}</h5>
                        </ng-container>
                    </div>
                </div>
                <form [formGroup]="assignUnitNumberForm">

                    <div class="field" style="margin-top: 2rem;">
                        <label htmlFor="spot">Selected trailers to be merged into<span
                                class="text-danger">*</span></label>
                        <p-dropdown [options]="unitNumberList" placeholder="Select Unit Number"
                            formControlName="unitNumber1" [(ngModel)]="unitNumber1"
                            (onChange)="selectOption($event)"></p-dropdown>

                    </div>

                    <div class="field">
                        <label htmlFor="client">Assign Client<span class="text-danger">*</span></label>
                        <p-dropdown [options]="clientList" placeholder="Select client" formControlName="client"
                            optionLabel="clientName" optionValue="clientId" [(ngModel)]="client"
                            (onChange)="selectOption1($event)"></p-dropdown>
                    </div>
                    <div *ngIf="userType !== 'ROLE_CLIENT' && selectedOption==='text'">

                        <div class="field">
                            <label htmlFor="unitNumber">Assign New Unit Number<span class="text-danger">*</span></label>
                            <input type="text" placeholder="Enter Unit Number" formControlName="unitNumber2"
                                [(ngModel)]="unitNumber2" (ngModelChange)="selectOption2($event)" pInputText />
                        </div>



                        <div class="field">
                            <label htmlFor="type">Type<span class="text-danger">*</span></label>
                            <p-dropdown [options]="dropdownItems" formControlName="type" placeholder="Select Type"
                                optionLabel="name" optionValue="code" [(ngModel)]="type"
                                (onChange)="selectOption3($event)"></p-dropdown>
                        </div>

                        <!-- <div class="field">
                <label htmlFor="carrier">Carrier<span class="text-danger">*</span></label> 
                <input type="text" placeholder="Enter carrier" formControlName="carrier" [(ngModel)]="carrier" (ngModelChange)="selectOption4($event)" pInputText />   
               </div>   -->



                        <div class="field">

                            <label htmlFor="carrier">Carrier<span class="text-danger">*</span></label>
                            <p-autoComplete formControlName="carrier" (completeMethod)="getCarrier($event)"
                                [(ngModel)]="carrier" (ngModelChange)="selectOption4($event)"
                                [suggestions]="uniqueCarrierList" dataKey="carrier" [dropdown]="true"
                                (onClear)="clearCarrier()">
                                <ng-template>
                                    <div>{{uniqueCarrierList}}</div>
                                    <!-- <div>{{uniqueCarrierList.carrier}}</div> -->
                                </ng-template>
                            </p-autoComplete>
                            
                            <!-- <span
                    class="text-danger"
                    *ngIf="
                    fleetForm.controls.carrier.touched &&
                    fleetForm.controls.carrier.invalid 
                    ">Carrier is required
                    </span> -->

                        </div>


                        <div class="field">
                            <label htmlFor="owner">Owner<span class="text-danger">*</span></label>
                            <input type="text" placeholder="Enter owner" formControlName="owner" [(ngModel)]="owner"
                                (ngModelChange)="selectOption5($event)" pInputText />
                        </div>



                    </div>



                    <!-- <div class="field">
                <label htmlFor="spot">Spot<span class="text-danger"></span>(optional)</label> 
            <p-dropdown [options]="mergeSpotList" placeholder="Select spot" formControlName="fleetIds" optionLabel="spotName" optionValue="spotId" [(ngModel)]="spotselected">></p-dropdown>
             
           </div> -->



                </form>
            </ng-template>

            <ng-template pTemplate="footer">

                <div *ngIf="isFormValid===false" style="text-align: left;">
                    <span class="text-danger">Enter all fields*</span>
                </div>

                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                    (click)="closeMergeTrailerPopup()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                    (click)="assignUnitNumberToMerge()" type="button"></button>



            </ng-template>

        </p-dialog>

        <p-dialog [(visible)]="carrierModel" [style]="{width: '500px', minHeight:'550px'}" header="Carrier Merge"
            [modal]="true" (onHide)="hideCarrierModel()" class="p-fluid">
            <ng-template pTemplate="content">

                <form [formGroup]="assignCarrierForm">

                    <div class="field">
                        <label htmlFor="carrier">Carriers<span class="text-danger">*</span></label>

                       
                        <p-multiSelect class='multiselect_display_block' [options]="carrierLIst" optionLabel="label" optionValue="label"
                            formControlName="renameCarriers" placeholder="Select carrier" resetFilterOnHide=true
                            display="chip" [showClear]="true"></p-multiSelect>
                            <span class="text-danger" *ngIf="renameCarrierRequired">This field is required.</span>
                        <!-- <p-autoComplete formControlName="renameCarriers" (completeMethod)="getCarrier($event,1)" [suggestions]="carrierLIst" [dropdown]="true" [multiple]="true" [forceSelection]="true" >
                        <ng-template>
                            <div>{{carrierLIst}}</div>
                        </ng-template>
                </p-autoComplete> -->
                    </div>

                    <div class="field">

                        <label htmlFor="carrier">Merge Carrier Name<span class="text-danger">*</span></label>
                        <p-autoComplete formControlName="carrier" (completeMethod)="getCarrier($event)"
                            [suggestions]="uniqueCarrierList" [dropdown]="true" onblur="saveTypedData($event)">
                            <ng-template>
                                <div>{{uniqueCarrierList}}</div>
                            </ng-template>
                        </p-autoComplete>
                        <span class="text-danger" *ngIf="mergecarrier_required">This field is required.</span>

                    </div>

                </form>
            </ng-template>

            <ng-template pTemplate="footer">

                <div *ngIf="!carrierFormValid" style="text-align: left;">
                    <span class="text-danger">Enter all fields*</span>
                </div>

                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
                    (click)="hideCarrierModel()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
                    (click)="saveCarrierMerge()" type="button"></button>



            </ng-template>

        </p-dialog>


    </div>
</div>