import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ManageTrailerAuditService } from './manage-trailer-audit.service';
import { Subscription } from 'rxjs';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { ErrorService } from 'src/app/error-handling/error.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { Paginator } from 'primeng/paginator';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { TokenService } from 'src/app/security/token.service';

@Component({
  selector: 'app-manage-trailer-audit',
  templateUrl: './manage-trailer-audit.component.html',
  styleUrls: ['./manage-trailer-audit.component.scss']
})
export class ManageTrailerAuditComponent implements OnInit {
  
  breadcrumbItems: MenuItem[];
  trailerAudit:any[];
  subscription = new Subscription();
  loading: boolean;
  query:IpagedQuery;
  totalRecords: any;
  accessToken: string;
  items: MenuItem[];
  userRoles: any;
  userType: any;
  fromDate;
  toDate;

  @ViewChild('paginator', { static: true }) paginator: Paginator;
 
  constructor(
    private manageTrailerAuditService : ManageTrailerAuditService,
    private loader:AppLoaderService,
    private errorService:ErrorService,
    private tokenService:TokenService,
    private router:Router
  ) { 

    this.items = [

      {
        label: 'Pdf',
        icon: 'pi pi-download',
        command: () => {
            this.exportPdf();
        }
    },

      {
          label: 'Excel',
          icon: 'pi pi-download',
          command: () => {
              this.exportExcel();
          }
      }
      // {
      //     label: 'CSV',
      //     icon: 'pi pi-download',
      //     command: () => {
      //         this.exportCsv();
      //     }
      // }
  ];


  }

  ngOnInit(): void {

    this.accessToken = this.tokenService.getAccessToken();
    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Trailer Audit'});
    this.query = {isActive:true,size:50,page:0};
    this.getTrailerAudit();
    this.userRoles = this.tokenService.getUserRoles();
      if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR)){
        this.userType = APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR;
      }
  }


  getTrailerAudit(from_date?:any, to_date?:any)
  {

    this.loading = true;
    this.subscription.add(
      this.manageTrailerAuditService.viewTrailerAudit(this.query,from_date, to_date).subscribe(response=>{
        this.trailerAudit= response.list;
        this.totalRecords = response.totalElements;
        console.log("trailerAudit",this.trailerAudit);
        this.loading = false;
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }

  private updateCurrentPage(currentPage: number): void {
    setTimeout(() => this.paginator.changePage(currentPage));
  }

  paginate(event){
    console.log("hello11111")
    this.query.page = event.page;
    console.log(this.query);
    this.getTrailerAudit(this.fromDate, this.toDate);
    // this.getFleetList(this.query,this.filterByClient,this.unitNumber,this.selectedTrailerStatus);
  }



  exportCsv(){
  
    let url = `${APP_CONSTANTS.BASE_API_URL}/v1/trailerAudit/export/csv?access_token=${this.accessToken}&isActive=${this.query.isActive}`;
    if(this.fromDate){
      url = url + '&fromDate=' + this.fromDate;
    }
    if(this.toDate){
      url = url + '&toDate=' + this.toDate;
    }
    window.open(url, '_blank');
    
  }
  exportPdf(){
  
    let url = `${APP_CONSTANTS.BASE_API_URL}/v1/trailerAudit/export/pdf?access_token=${this.accessToken}&isActive=${this.query.isActive}`;
    if(this.fromDate){
      url = url + '&fromDate=' + this.fromDate;
    }
    if(this.toDate){
      url = url + '&toDate=' + this.toDate;
    }
    window.open(url, '_blank');
    
  }

  exportExcel()
  {

    let url = `${APP_CONSTANTS.BASE_API_URL}/v1/trailerAudit/export/excel?access_token=${this.accessToken}&isActive=${this.query.isActive}`;
    if(this.fromDate){
      url = url + '&fromDate=' + this.fromDate;
    }
    if(this.toDate){
      url = url + '&toDate=' + this.toDate;
    }
    window.open(url, '_blank');
  }


  routeToAddTrailerAudit(){
    this.router.navigate(['/main/add-trailer-audit'])
  }

  filterJobsByDate(){
   
    if(this.toDate !== undefined || this.fromDate !== undefined){
      console.log("dddd");
      if(this.trailerAudit.length == 0)
      {
        this.getTrailerAudit(this.fromDate,this.toDate); 
      }
      else
      {
        this.updateCurrentPage(0);
      }
          
    }

  }

}
