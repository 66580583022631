<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <p-breadcrumb [model]="breadcrumbItems" [home]="{icon: 'pi pi-home',routerLink:'../'}"></p-breadcrumb>
        </div>
    </div>
    <div class="col-12">
        <div class="card">
            <p-toast></p-toast>
            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                    </div>
                </ng-template>

                <ng-template pTemplate="right">
                    <button *ngIf="activeTab == 0" pButton pRipple label="New" icon="pi pi-plus" class="p-button-secondary mr-2" (click)="createNew()"></button>
                </ng-template>
            </p-toolbar>

            <p-tabView (onChange)="handleTabChange($event)" [(activeIndex)]="activeTab">
               
                <p-tabPanel header="Suppliers">
                    <p-table #dt [loading]="loading" [value]="supplierList" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['type']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">
                       
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 10%;"></th>
                                <!-- <th style="width: 10%;" pSortableColumn="Id">ID</th> -->
                                <th pSortableColumn="to">Suppliers</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-supplier let-i="rowIndex">
                            <tr>
                                <td>
                                    <button
                                    pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                    class="p-button-rounded p-button-help mr-2"
                                    (click)="editSupplier(supplier)" *ngIf="userType !='ROLE_GUARD'"></button>
                                <button
                                    pButton pRipple icon="pi pi-trash" pTooltip="Delete"
                                    class="p-button-rounded p-button-danger mr-2"
                                    (click)="deleteSupplier(supplier)" *ngIf="userType !='ROLE_GUARD'"></button>
                                </td>
                                <!-- <td>
                                    <span class="p-column-title">ID</span>
                                    {{supplier?.id}}
                                </td> -->
                                <td>
                                    <span class="p-column-title">Suppliers</span>
                                    {{supplier?.supplier}}
                                </td>
                                
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-paginator [rows]="50" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
                </p-tabPanel>
                <p-tabPanel header="Carriers">
                    <p-table #dt [loading]="loading" [value]="carrierList" styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm" [responsive]="true" [columns]="cols" [rows]="10" [globalFilterFields]="['type']" [rows]="10" [paginator]="false" [rowHover]="true" dataKey="id" [responsiveLayout]="'scroll'">
                        
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="width: 10%;"></th> -->
                                <!-- <th style="width: 10%;" pSortableColumn="Id">ID</th> -->
                                <th pSortableColumn="to">Carriers</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-carrier let-i="rowIndex">
                            <tr>
                                <!-- <td>
                                    <button
                                        pButton pRipple icon="pi pi-pencil" pTooltip="Edit"
                                        class="p-button-rounded p-button-help mr-2"
                                        (click)="editCarrier(carrier)" *ngIf="userType !='ROLE_GUARD'"></button>
                                    <button
                                        pButton pRipple icon="pi pi-trash" pTooltip="Delete"
                                        class="p-button-rounded p-button-danger mr-2"
                                        (click)="deleteCarrier(carrier)" *ngIf="userType !='ROLE_GUARD'"></button>    
                                </td> -->
                                <!-- <td>
                                    <span class="p-column-title">ID</span>
                                    {{carrier?.id}}
                                </td> -->
                                <td>
                                    <span class="p-column-title">Carriers</span>
                                    {{carrier}}
                                </td>
                                
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-paginator [rows]="50" [showCurrentPageReport] = "true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" [totalRecords]="totalRecords"  (onPageChange)="paginate($event)"></p-paginator>
                </p-tabPanel>
            </p-tabView>
            
            

            
        </div>

        <p-dialog [(visible)]="newSupplier" [style]="{width: '450px'}" header="Create Supplier" [modal]="true" class="p-fluid" (onHide)="hideDialogSupplier()">
            <ng-template pTemplate="content">
                <form [formGroup]="supplierForm">
                    <div class="field">
                        <label for="fleet">Supplier</label>
                        <input pInputText id="supplier" type="text" formControlName="supplier" />
                        <small class="text-danger ng-dirty ng-invalid" *ngIf="supplierForm.controls.supplier.touched &&
                        supplierForm.controls.supplier.invalid">Supplier is required.</small>
                    </div>
                    
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialogSupplier()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="createSupplier()"></button>
            </ng-template>
        </p-dialog>
<!-- 
        <p-dialog [(visible)]="newCarrier" [style]="{width: '450px'}" header="Create Carrier" [modal]="true" class="p-fluid" (onHide)="hideDialogCarrier()">
            <ng-template pTemplate="content">
                <form [formGroup]="carrierForm">
                    <div class="field">
                        <label for="fleet">Carrier</label>
                        <input pInputText id="carrier" type="text" formControlName="carrier" />
                        <small class="text-danger ng-dirty ng-invalid" *ngIf="carrierForm.controls.carrier.touched &&
                        carrierForm.controls.carrier.invalid">Carrier is required.</small>
                    </div>
                    
                </form>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialogCarrier()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" type="button" (click)="createCarrier()"></button>
            </ng-template>
        </p-dialog> -->

        <p-dialog [(visible)]="deleteDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}" (onHide)="deleteCancelSupplier()">
            <div class="flex align-items-center justify-content-start">
                <i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
                <span>Are you sure you want to delete?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No"
                    (click)="deleteCancelSupplier()"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
            </ng-template>
        </p-dialog>

        <!-- <p-dialog [(visible)]="deleteCarrierDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}" (onHide)="deleteCancelCarrier()">
            <div class="flex align-items-center justify-content-start">
                <i class="pi pi-exclamation-triangle mr-3 my-4" style="font-size: 2rem"></i>
                <span>Are you sure you want to delete?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No"
                    (click)="deleteCancelCarrier()"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmCarrierDelete()"></button>
            </ng-template>
        </p-dialog> -->

    </div>
</div>
