import { Component, OnInit, OnDestroy } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { ManageClientsService } from './manage-clients.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { Subscription } from 'rxjs';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { ErrorService } from 'src/app/error-handling/error.service';
import { WebSocketService } from 'src/app/web-socket.service';
import { DashboardService } from '../dashboard/dashboard.service';
import * as moment from 'moment';

@Component({
    selector: 'app-manage-clients',
    templateUrl: './manage-clients.component.html',
    providers: [MessageService, ConfirmationService],
    styleUrls: ['../../../assets/demo/badges.scss','./manage-clients.component.scss']
})
export class ManageClientsComponent implements OnInit, OnDestroy {

    clients = [];
    cols: any[];
    breadcrumbItems: MenuItem[];
    deleteClientDialog: boolean;
    loading: boolean;
    query:IpagedQuery;
    totalRecords: any;
    client;
    subscription = new Subscription();
    userRoles: any;
    userType: string;
    accessToken: string;
    hideButtons: boolean = false;
    hideButtonsIfDriver:boolean = false;
    hideButtonsIfSupervisor:boolean = false;
    hideFlagAccountDeletion:boolean = true;
    formats:string[]=["csv","excel"];
    selectedFormat:string;
    items: MenuItem[];
    clientConfigModal:boolean;
    selectedClient;
    assignObj; 
    value: boolean; 
    stateOptions: any[] = [{label: 'Disable', value: false}, {label: 'Enable', value: true}];
    private webSocket: WebSocket;
    todayQuery:IpagedQuery;
    messageStatistics = {
        newCount:0,
        readCount:0
    }
    

    constructor(
        private router:Router,
        private manageClientsService:ManageClientsService,
        private loader:AppLoaderService,
        private alertService:AppAlertService,
        private tokenService:TokenService,
        private errorService:ErrorService,
        private webSocketService:WebSocketService,
        private dashboardService:DashboardService
    ) {


        this.webSocket = new WebSocket(APP_CONSTANTS.SOCKET_URL);
        this.webSocket.onmessage = (event) => {
            
            this.getMessageStatics();
           console.log("clients");
            
           
        };

        this.items = [
            {
                label: 'Excel',
                icon: 'pi pi-download',
                command: () => {
                    this.exportExcel();
                }
            }
            // ,
            // {
            //     label: 'CSV',
            //     icon: 'pi pi-download',
            //     command: () => {
            //         this.exportCsv();
            //     }
            // }
        ];
    }

    ngOnInit() {


        let nowTime =  moment().format('yyyy-MM-DD HH:mm');
        let twentyFourHours =  moment().add(-24,'hours').format('yyyy-MM-DD HH:mm');
        this.todayQuery = {
            fromDateTime:twentyFourHours,
            toDateTime:nowTime
        }

        this.clientConfigModal=false;
        this.userRoles = this.tokenService.getUserRoles();
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)){
          this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
        }
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER 
            || role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD 
            || role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER
            )){
            this.hideButtons = true;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER))
        {
            this.hideButtonsIfDriver = true;
        }
        if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN 
            || role === APP_CONSTANTS.USER_ROLES.SUPER_ADMIN 
            || role === APP_CONSTANTS.USER_ROLES.ROLE_IT
            )){
            this.hideFlagAccountDeletion = false;
        }
        if(this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR))
        {
            this.hideButtonsIfSupervisor = true;
        }
        this.accessToken = this.tokenService.getAccessToken();
        this.query = {isActive:true,size:10,page:0}
        
        this.viewClients(this.query);

        this.cols = [
            { field: 'clientName', header: 'Client Name' },
            { field: 'street', header: 'Street' },
            { field: 'city', header: 'City' },
            { field: 'state', header: 'State' },
            { field: 'country', header: 'Country' }
        ];

        this.breadcrumbItems = [];
        this.breadcrumbItems.push({ label: 'Clients'});
        
    }

    viewClients(query:IpagedQuery){
        this.loading = true;
        this.manageClientsService.viewClients(query).subscribe(response=>{
          this.clients = response.list;
          if(this.userType){
              let client = this.clients.find(el=> el.clientId == this.tokenService.getUserClientId());
              this.routeToLocation(client["clientId"],client["clientName"])
          }
          this.totalRecords = response.totalElements;
          this.loading = false;
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
    }

    deleteClientConfirmation(client) {
        this.deleteClientDialog = true;
        this.client = client;
    }

    confirmDelete(clientId){
        this.loader.show();
        this.subscription.add(
        this.manageClientsService.deleteClient(clientId).subscribe(res=>{
            this.deleteClientDialog = false;
            this.alertService.alertSuccess(['Client Deleted/Deactivate Successfully']);
            this.viewClients(this.query);
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        })
        )
    }
    
    ngOnDestroy(): void {    
        this.subscription.unsubscribe()
    }

    routeToAddClient(){
        this.router.navigate(['/main/add-client'])
    }

    routeToLocation(clientId,clientName){
        this.router.navigate(['/main/manage-locations'],{queryParams:{clientId,clientName}})
    }
    
    routeToDetails(clientId){
        this.router.navigate(['/main/client-details'],{queryParams:{clientId}});
    }

    routeToEdit(clientId){
        this.router.navigate(['/main/add-client'],{queryParams:{clientId}})
    }

    paginate(event){
        this.query.page = event.page;
        this.viewClients(this.query);
    }

    exportCsv(){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/clients/export/csv?access_token=${this.accessToken}`, '_blank');
    }
    exportExcel(){
        window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/clients/export/excel?access_token=${this.accessToken}`, '_blank');
    }

    hideDialog1(){
        this.clientConfigModal=false;
        this.viewClients(this.query);
    }

    showConfig(client){
        this.selectedClient=client;
        this.clientConfigModal=true;
    }

    // enableDisable(key:string){
    //     console.log(this.selectedClient.bol);
    //      switch(key){
    //         case 'bol':
    //             this.assignObj = {
    //                 clientId : this.selectedClient.clientId,
    //                 bol : this.selectedClient.bol
    //                 } 
    //              break;   
    //          case 'dvir':
    //             this.assignObj = {
    //                 clientId : this.selectedClient.clientId,
    //                 dvir : this.selectedClient.dvir
    //                 } 
    //              break;   
    //      }
    //      this.manageClientsService.updateClientConfig(this.assignObj).subscribe(res=>{
    //         this.alertService.alertSuccess(['Client Config Updated Successfully']);
    //         this.loader.hide();
    //     },(error) => {
    //         this.loader.hide();
    //         this.errorService.handleError(error, true);
    //     });
          
        
    // }

    onSelectChange(key:string,event:any){
        console.log("event",event);
         switch(key){
            case 'bol':
                this.assignObj = {
                    clientId : this.selectedClient.clientId,
                    bol : this.selectedClient.bol
                    } 
                 break;   
            case 'dvir':
                this.assignObj = {
                    clientId : this.selectedClient.clientId,
                    dvir : this.selectedClient.dvir
                    } 
                 break; 
            case 'trailerAudit':
                 this.assignObj = {
                     clientId : this.selectedClient.clientId,
                     trailerAudit : this.selectedClient.trailerAudit
                     } 
                  break; 
            case 'accountDeactivation':
                this.assignObj = {
                    clientId : this.selectedClient.clientId,
                    accountDeactivation : this.selectedClient.accountDeactivation
                    } 
                    break;       
         }
         this.manageClientsService.updateClientConfig(this.assignObj).subscribe(res=>{
            this.alertService.alertSuccess(['Client Config Updated Successfully']);
            this.loader.hide();
        },(error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
        });
    }

    getMessageStatics()
    {
      this.dashboardService.getMessagesCount(this.todayQuery).subscribe(response=>{
             this.messageStatistics = response;
             this.dashboardService.sendMessageCount(this.messageStatistics.newCount);
             
        }
      )
    }

    

}
