import { Component, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../api/product';
import { ProductService } from '../../service/productservice';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageJobsService } from './manage-jobs.service';
import { AppLoaderService } from 'src/app/app-loader/service/app-loader.service';
import { IpagedQuery } from 'src/app/model/IpagedQuery';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppAlertService } from 'src/app/app-alert/service/app-alert.service';
import { ManageClientsService } from '../manage-clients/manage-clients.service';
import { TokenService } from 'src/app/security/token.service';
import { APP_CONSTANTS } from 'src/app/constants/app.constants';
import { Subscription, concatMap, forkJoin, map, switchMap } from 'rxjs';
import * as moment from 'moment';
import { ErrorService } from 'src/app/error-handling/error.service';
import { AddJobService } from '../add-job/add-job.service';
import { ManageSpotsService } from '../manage-spots/manage-spots.service';
import { ManageFleetsService } from '../manage-fleets/manage-fleets.service';
import { ManageLocationsService } from '../manage-locations/manage-locations.service';
import { text } from '@fortawesome/fontawesome-svg-core';
import { Paginator } from 'primeng/paginator';
import { HttpClient } from '@angular/common/http';
import { Position } from '@angular/compiler';
import { ManageUsersService } from '../manage-users/manage-users.service';
import { WebSocketService } from 'src/app/web-socket.service';



@Component({
  selector: 'app-manage-jobs',
  templateUrl: './manage-jobs.component.html',
  providers: [MessageService, ConfirmationService],
  styleUrls: ['../../../assets/demo/badges.scss', './manage-jobs.component.scss']
})
export class ManageJobsComponent implements OnInit {

  breadcrumbItems: MenuItem[];

  productDialog: boolean;

  deleteProductDialog: boolean = false;

  deleteProductsDialog: boolean = false;

  jobs = [
    // {
    //     id:"46231",
    //     pickupLocation: '185 Geneese St, Utika, North Dakota, USA',
    //     pickupSpot: 'D37',
    //     dropLocation: '808 Berry St, St Paul,Minnesota, USA',
    //     dropSpot: 'D82',
    //     jobDescription: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //     jobPriority: 'High',
    //     driver:'John Doe',
    //     truck:'12NZ456'
    // },
    // {
    //   id:"23512",
    //   pickupLocation: '185 Geneese St, Utika, North Dakota, USA',
    //   pickupSpot: 'D37',
    //   dropLocation: '808 Berry St, St Paul,Minnesota, USA',
    //   dropSpot: 'D82',
    //   jobDescription: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //   jobPriority: 'Medium',
    //   driver:'Chris Ramsey',
    //   truck:'76FG456'
    // },
    // {
    //   id:"12313",
    //   pickupLocation: '185 Geneese St, Utika, North Dakota, USA',
    //   pickupSpot: 'D37',
    //   dropLocation: '808 Berry St, St Paul,Minnesota, USA',
    //   dropSpot: 'D82',
    //   jobDescription: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //   jobPriority: 'Low',
    //   driver:'Dwayne Clark',
    //   truck:'21XY456'
    // },
    // {
    //   id:"12512",
    //   pickupLocation: '185 Geneese St, Utika, North Dakota, USA',
    //   pickupSpot: 'D37',
    //   dropLocation: '808 Berry St, St Paul,Minnesota, USA',
    //   dropSpot: 'D82',
    //   jobDescription: 'DD14-This trailer has racks and needs to be moved to the left slightly',
    //   jobPriority: 'High',
    //   driver:'Smith Joe',
    //   truck:'41DR456'
    // },
  ];

  product: Product;

  selectedProducts: Product[];

  submitted: boolean;

  cols: any[];

  statuses: any[];

  type: any[];

  rowsPerPageOptions = [5, 10, 20];

  modalTitle;

  clientList = [];

  status: { name: string; code: string; }[];

  trailerStatus = [
    { name: 'Empty', code: 'EMPTY' },
    { name: 'Loaded', code: 'FULL' }
  ];

  date;
  totalRecordsOpen: any;
  totalRecordsIntransit: any;
  totalRecordsCompleted: any;
  totalRecordsDriverQueue: any;
  totalRecordsSpotterQueue: any;
  totalRecordsException: any;
  queryUser: IpagedQuery;
  query: IpagedQuery;
  queryIntransit: IpagedQuery;
  queryCompleted: IpagedQuery;
  queryException: IpagedQuery;
  queryDriverQueue: IpagedQuery;
  querySpotterQueue: IpagedQuery;
  loading = false;
  loadingIntransit = false;
  loadingComplete = false;
  loadingDriverQueue = false;
  loadingSpotterQueue = false;
  loadingException = false;
  jobId: any;
  jobStatusDialog: boolean;
  jobStatusForm: FormGroup
  modalJob: any;
  filterByStatus: any;
  clientQuery: { isActive: boolean; size: number; page: number; };
  filterByClient: any;
  userRoles: any;
  userType: any;
  accessToken: string;
  jobForm: FormGroup;
  jobSearchForm: FormGroup;
  dropSpots: any[];
  locationList: any[];
  spotsQuery: { isActive: boolean; size: number; page: number; };
  locationQuery: { isActive: boolean; size: number; page: number; };
  trailerQuery: { isActive: boolean; size: number; page: number; };
  fromDate;
  toDate;
  deleteJobDialog: boolean;
  searchJobDialog: boolean;
  bolDialog: boolean;
  deleteJobId: any;
  filteredFleets: any[];
  filterFleetId;
  selectedfleetId;
  items: MenuItem[];
  clientId: string;
  selectedBol: any[];
  selectedImagePath: string = '';
  hideButtonsIfDriver: boolean = false;
  hideButtonsIfClient: boolean = false;
  hideButtonsIfGuard: boolean = false;
  hideButtonsIfSpotter: boolean = false;
  lat: string;
  lng: string;
  filterJobCreatedDateModal: boolean = false;
  dialogPositionLeft: number = 0;
  dialogPositionTop: number = 0;
  appendToBody: boolean = true;
  createdDateSort = [
    { name: 'ascending', code: 'asc' },
    { name: 'descending', code: 'desc' }
  ];
  sortCreatedDate: string;
  filterAssignedToModal: boolean = false;
  drivers: any[];
  allUsers:any[];
  isDriver: boolean = false;
  isSpotter: boolean = false;
  userId: any;
  assignedTo: string;
  createdBy: string;
  selectedUser: any[];
  assignedDriver: any;
  assignedDriverIntransit: any;
  assignedDriverCompleted: any;
  assignedDriverException: any;
  createdUserOpen: any;
  createdUserIntransist: any;
  createdUserCompleted: any;
  createdUserDriverQueue: any;
  createdUserSpotterQueue: any;
  selectedSortDate: any;
  selectedSortDateIntransit: any;
  selectedSortDateCompleted: any;
  selectedSortDateException: any;
  jobsOpen = [];
  jobsIntransit = [];
  jobsCompleted = [];
  jobsDriverQueue = [];
  jobsSpotterQueue = [];
  jobsException = [];
  exceptionDialog: boolean = false;
  exceptionJobId: any;
  selectedException: any = "";
  exceptionInputText: any;
  accordionActiveIndex: any = 0;
  exceptionLists: any = [
    {name: "Air Lines"},
    {name: "Brakes Frozen"},
    {name: "Landing Gear"},
    {name: "Tires Flat"},
    {name: "Not Ready"},
    {name: "Occupied"},
    {name: "Blocked"},
    {name: "Dock Lock Engaged"},
    {name: "Out of Service"},
    {name: "Tandems"},
    {name: "Different Empty"},
    {name: "Snow"},
    {name: "Door Closed"},
    {name: "Red Light"},
    {name: "Other"}
  ];
  exceptionActive: boolean = false;
  subscription = new Subscription();
  searchNotes: any;
  isSupervisorOrClient: boolean = false;
  isSupervisor: boolean = false;
  private webSocket: WebSocket;
  i:number;// 
  j:number;
  k:number;
  l:number;

  @ViewChild('paginator1', { static: false }) paginator1: Paginator;
  @ViewChild('paginator2', { static: false }) paginator2: Paginator;
  @ViewChild('paginator3', { static: false }) paginator3: Paginator;
  @ViewChild('paginator4', { static: false }) paginator4: Paginator;
  @ViewChild('paginator5', { static: false }) paginator5: Paginator;
  @ViewChild('paginator6', { static: false }) paginator6: Paginator;

  constructor(private router: Router,
    private manageJobsService: ManageJobsService,
    private manageClientsService: ManageClientsService,
    private loader: AppLoaderService,
    private fb: FormBuilder,
    private alertService: AppAlertService,
    private tokenService: TokenService,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private addJobService: AddJobService,
    private manageSpotsService: ManageSpotsService,
    private manageLocationsService: ManageLocationsService,
    private manageFleetsService: ManageFleetsService,
    private manageUserService: ManageUsersService,
    private http: HttpClient,
    private webSocketService:WebSocketService) {


      this.webSocket = new WebSocket(APP_CONSTANTS.SOCKET_URL);
      this.webSocket.onmessage = (event) => {
          if(event.data == "Jobs Updated")
          {
            setTimeout(() => {

              this.getJobs(this.querySpotterQueue, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
              this.getJobs(this.querySpotterQueue, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
              this.getJobs(this.querySpotterQueue, "COMPLETED", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);          
              this.getJobs(this.querySpotterQueue, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_DRIVER", this.createdBy);
              this.getJobs(this.querySpotterQueue, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_SPOTTER", this.createdBy);

            },3000);         
          }
      };

    this.activatedRoute.queryParams.subscribe(qparams => {
      if (qparams['filter']) {
        this.filterByStatus = qparams['filter'];
      }
    })

    this.jobStatusForm = this.fb.group({
      status: ['', Validators.required],
      notes: [''],
      statusDateTime: [null]
      // lat:[''],
      // lng:['']
    });

    this.jobForm = this.fb.group({
      assignedToUserId: ['', Validators.required],
      description: [''],
      dropLocationId: ['', Validators.required],
      dropSpotId: [''],
      fleetId: ['', Validators.required],
      fleetStatus: [null],
      pickupLocationId: ['', Validators.required],
      pickupSpotId: ['', Validators.required],
      priority: ['', Validators.required],
      clientId: ['', Validators.required],
      sequenceAsn: ['']
    });

    //  this.jobSearchForm = this.fb.group({
    //      searchvalue:['',Validators.required]
    //  });


    this.items = [
      {
        label: 'Excel',
        icon: 'pi pi-download',
        command: () => {
          this.exportExcel();
        }
      }
      // ,
      // {
      //   label: 'CSV',
      //   icon: 'pi pi-download',
      //   command: () => {
      //     this.exportCsv();
      //   }
      // }
    ];


  }

  ngOnInit() {


    this.i=0;
    this.j=0;
    this.k=0;
    this.l=0;

    this.userId = this.tokenService.getUserId();
    // this.getLocation();
    this.bolDialog = false;
    this.userRoles = this.tokenService.getUserRoles();
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT && role !== APP_CONSTANTS.USER_ROLES.ROLE_ADMIN && role !== APP_CONSTANTS.USER_ROLES.ROLE_IT)) {
      this.userType = APP_CONSTANTS.USER_ROLES.ROLE_CLIENT;
    }
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_DRIVER)) {
      this.hideButtonsIfDriver = true;
      this.isDriver = true;
    }
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_CLIENT || role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR || role === APP_CONSTANTS.USER_ROLES.ROLE_IT || role === APP_CONSTANTS.USER_ROLES.ROLE_ADMIN)) {
      this.isSupervisorOrClient = true;
    }
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_GUARD)) {
      this.hideButtonsIfGuard = true;
    }
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SPOTTER)) {
      this.hideButtonsIfSpotter = true;
      this.isSpotter = true;
    }
    if (this.userRoles.some(role => role === APP_CONSTANTS.USER_ROLES.ROLE_SUPERVISOR)) {
         this.isSupervisor = true;
    }


    this.accessToken = this.tokenService.getAccessToken();
    this.query = { isActive: true, size: 50, page: 0 };
    this.queryIntransit = { isActive: true, size: 50, page: 0 };
    this.queryCompleted = { isActive: true, size: 50, page: 0 };
    this.queryDriverQueue = { isActive: true, size: 50, page: 0 };
    this.querySpotterQueue = { isActive: true, size: 50, page: 0 };
    this.queryException = { isActive: true, size: 50, page: 0 };
    this.spotsQuery = { isActive: true, size: 1000, page: 0 };
    this.locationQuery = { isActive: true, size: 1000, page: 0 };
    this.queryUser = {isActive: true, size: 1000, page: 0 };
    // if(this.filterByStatus) {
    //   this.getJobs(this.query,this.filterByStatus)
    // }else{
    //   this.getJobs(this.query);
    // }
    this.getJobs(this.query, "OPEN");
    this.getJobs(this.queryIntransit, "IN_TRANSIT");
    this.getJobs(this.queryCompleted, "COMPLETED");
    this.getJobs(this.queryDriverQueue, "QUEUE",null,null,null,null,null,null,null,"BUCKET_DRIVER");
    this.getJobs(this.querySpotterQueue, "QUEUE",null,null,null,null,null,null,null,"BUCKET_SPOTTER");
    // this.getJobs(this.queryException, "EXCEPTION");
    this.clientQuery = { isActive: true, size: 1000, page: 0 };
    this.getClientList(this.clientQuery);
    this.trailerQuery = { isActive: true, size: 2000, page: 0 };

    this.status = [
      { name: 'Open', code: 'OPEN' },
      { name: 'In-Transit', code: 'IN_TRANSIT' },
      { name: 'Completed', code: 'COMPLETED' }
    ];

    this.breadcrumbItems = [];
    this.breadcrumbItems.push({ label: 'Spots' });

    this.viewUsers(this.queryUser);
    this.viewAllUsers(this.queryUser);

    if(this.filterByStatus == "IN_TRANSIT"){
      this.accordionActiveIndex = 1;
    }else if(this.filterByStatus == "COMPLETED"){
      this.accordionActiveIndex = 2;
    }else if(this.filterByStatus == "EXCEPTION"){
      this.accordionActiveIndex = 4;
    }else{
      this.accordionActiveIndex = 0;
    }
  }

  extractTextBeforeColon(text) {
    let colonIndex = text.indexOf(':');
    if (colonIndex !== -1) {
      return text.substring(0, colonIndex + 1);
    }
    return null; // Return full text if ':' is not found
  }

  removeTextBeforeColon(text) {
    let parts = text.split(':');
    if (parts.length > 1) {
      return parts[1].trim();
    }
    return text.trim(); // Return trimmed text if ':' is not found
  }

  getJobs(query, status?: any, clientId?: any, fromDate?: any, toDate?: any, fleetId?: any, sort?: any, assignedTo?: string, notes?: any, bucket?:string, createdBy?:any) {
   
    if (status == "OPEN") {
      this.loading = true;
    } else if (status == "IN_TRANSIT") {
      this.loadingIntransit = true;
    } else if (status == "COMPLETED") {
      this.loadingComplete = true;
    } else if (status == "EXCEPTION") {
      this.loadingException = true;
    } else if (status == "QUEUE" && bucket == "BUCKET_DRIVER") {
      this.loadingDriverQueue = true;
    } else if (status == "QUEUE" && bucket == "BUCKET_SPOTTER") {
      this.loadingSpotterQueue = true;
    }
    
    
    this.manageJobsService.viewJobs(query, status, clientId, fromDate, toDate, fleetId, sort, assignedTo, notes,bucket,createdBy)
      .pipe(
        map(jobs => {
          let jobList = [];
          for (let job of jobs.list) {
            let obj = {
              ...job,
              jobCompletionSeconds: this.jobCompletionTime(job.jobCompletionSeconds)
              //job.jobCompletionSeconds ? moment.utc(job.jobCompletionSeconds*1000).format('HH:mm:ss') : '-'
            }
            if (job.description) {
              var descriptionRole = this.extractTextBeforeColon(job.description);
              var description = this.removeTextBeforeColon(job.description);
              obj = {
                ...obj,
                description: description,
                descriptionRole: descriptionRole
              }
            }
            if (job.pickupNotes) {
              var pickupRole = this.extractTextBeforeColon(job.pickupNotes);
              var pickupNotes = this.removeTextBeforeColon(job.pickupNotes);
              obj = {
                ...obj,
                pickupNotes: pickupNotes,
                pickupRole: pickupRole
              }
            }

            if (job.dropNotes) {
              var dropRole = this.extractTextBeforeColon(job.dropNotes);
              var dropNotes = this.removeTextBeforeColon(job.dropNotes);
              obj = {
                ...obj,
                dropNotes: dropNotes,
                dropRole: dropRole
              }
            }
            jobList.push(obj);
          }
          return {
            ...jobs,
            list: jobList
          }
        })
      )
      .subscribe(response => {
        if (status == "OPEN") {
          this.jobsOpen = response.list;
          this.totalRecordsOpen = response.totalElements;
          this.loading = false;
        } else if (status == "IN_TRANSIT") {
          this.jobsIntransit = response.list;
          this.totalRecordsIntransit = response.totalElements;
          this.loadingIntransit = false;
        } else if (status == "COMPLETED") {
          this.jobsCompleted = response.list;
          this.totalRecordsCompleted = response.totalElements;
          this.loadingComplete = false;
        } else if (status == "QUEUE" && bucket == "BUCKET_DRIVER") {
          this.jobsDriverQueue = response.list;
          this.totalRecordsDriverQueue = response.totalElements;
          this.loadingDriverQueue = false;   
        }else if (status == "QUEUE" && bucket == "BUCKET_SPOTTER") {
          this.jobsSpotterQueue = response.list;
          this.totalRecordsSpotterQueue = response.totalElements;
          this.loadingSpotterQueue = false;   
        }else if (status == "EXCEPTION") {
          this.jobsException = response.list;
          this.totalRecordsException = response.totalElements;
          this.loadingException = false;
          if(this.jobsException.length > 0 && this.filterByStatus == "EXCEPTION"){
              this.accordionActiveIndex = 0;
          }
        }


        // var weather = this.jobs[0].weather;
        // var json = JSON.parse(weather);
        // console.log(weather);
        // console.log(json);
        // console.log(json.main.temp);
        // console.log(json.weather[0].main);


        
      }, (error) => {
        this.loader.hide();
        if (status == "OPEN") {
          this.loading = false;
        } else if (status == "IN_TRANSIT") {
          this.loadingIntransit = false;
        } else if (status == "COMPLETED") {
          this.loadingComplete = false;
        } else if (status == "EXCEPTION") {
          this.loadingException = false;
        } else if (status == "QUEUE" && bucket == "BUCKET_DRIVER") {
          this.loadingDriverQueue = false;
        } else if (status == "QUEUE" && bucket == "BUCKET_SPOTTER") {
          this.loadingSpotterQueue = false;
        }

        this.errorService.handleError(error, true);
      })
  }

  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  saveProduct() {
    this.submitted = true;
  }

  routeToAddJob() {
    this.router.navigate(['/main/add-job'])
  }

  routeToEdit(id,status?:string) {
    this.router.navigate(['/main/add-job'], { queryParams: { id,status,page: this.query.page, size: this.query.size } })
  }

  openStatusDialog(job, title) {
    this.modalTitle = title;
    this.jobId = job.jobId;
    this.modalJob = job;
    this.jobStatusDialog = true;

    this.getSpots(this.spotsQuery, job.pickupLocation.clientId, job.dropLocation.locationId);
    this.getClientLocations(this.locationQuery, job.pickupLocation.clientId);
    this.jobForm.patchValue({
      clientId: job.pickupLocation.clientId ? job.pickupLocation.clientId : '',
      assignedToUserId: job.assignedTo.userId ? job.assignedTo.userId : '',
      description: job.description ? job.description : '',
      dropLocationId: job.dropLocation.locationId ? job.dropLocation.locationId : '',
      dropSpotId: job.dropSpot ? job.dropSpot.spotId : '',
      fleetId: job.fleet.fleetId ? job.fleet.fleetId : '',
      fleetStatus: job.fleetStatus ? job.fleetStatus : null,
      pickupLocationId: job.pickupLocation.locationId ? job.pickupLocation.locationId : '',
      pickupSpotId: job.pickupSpot ? job.pickupSpot.spotId : '',
      priority: job.priority ? job.priority : '',
      sequenceAsn: job.sequenceAsn ? job.sequenceAsn : ''
    })

    console.log(this.jobForm.value)
  }

  // paginate(event){
  //   this.query.page = event.page;
  //   if(this.filterByStatus && this.filterByClient){
  //     this.getJobs(this.query,this.filterByStatus,this.filterByClient)
  //   }else if(this.filterByStatus){
  //     this.getJobs(this.query,this.filterByStatus)
  //   }else if(this.filterByClient){
  //     this.getJobs(this.query,null,this.filterByClient)
  //   }else{
  //     this.getJobs(this.query)
  //   }
  //   // this.filterByStatus ? this.getJobs(this.query,this.filterByStatus) : this.getJobs(this.query);
  //   // this.filterByClient ? this.getJobs(this.query,null,this.filterByClient) : this.getJobs(this.query);
  // }

  paginate(event, status: any) {
    this.query.page = event.page;
    this.getJobs(this.query, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
  }

  paginateIntransit(event, status: any) {
    this.queryIntransit.page = event.page;
    this.getJobs(this.queryIntransit, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
  }

  paginateCompleted(event, status: any) {
    this.queryCompleted.page = event.page;
    this.getJobs(this.queryCompleted, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
  }

  paginateExceptions(event, status: any) {
    this.queryException.page = event.page;
    this.getJobs(this.queryException, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
  }

  paginateDriverQueue(event, status: any) {
    this.queryDriverQueue.page = event.page;
    this.getJobs(this.queryDriverQueue, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, "BUCKET_DRIVER", this.createdBy);
  }

  paginateSpotterQueue(event, status: any) {
    this.querySpotterQueue.page = event.page;
    this.getJobs(this.querySpotterQueue, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_SPOTTER", this.createdBy);
  }

  private updateCurrentPage(currentPage: number): void {
    setTimeout(() => this.paginator1.changePage(currentPage));
  }

  private updateCurrentPageIntransit(currentPage: number): void {
    setTimeout(() => this.paginator2.changePage(currentPage));
  }

  private updateCurrentPageCompleted(currentPage: number): void {
    setTimeout(() => this.paginator3.changePage(currentPage));
  }

  private updateCurrentPageException(currentPage: number): void {
    setTimeout(() => this.paginator4.changePage(currentPage));
  }
  
  private updateCurrentPageDriverQueue(currentPage: number): void {
    setTimeout(() => this.paginator5.changePage(currentPage));
  }

  private updateCurrentPageSpotterQueue(currentPage: number): void {
    setTimeout(() => this.paginator6.changePage(currentPage));
  }
  // openStatusDialog(jobId){
  //   this.jobId = jobId;
  //   this.jobStatusDialog = true;
  // }

  hidejobStatusDialog() {
    this.jobStatusDialog = false;
    this.jobId = null;
    this.modalJob = undefined;
  }

  //  getLocation():void {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position: any) => {
  //       if (position) {

  //         this.lat = position.coords.latitude;
  //         this.lng = position.coords.longitude;
  //         console.log("lat",this.lat);
  //         console.log("lng",this.lng);
  //       }
  //     },
  //       (error: any) => console.log(error));
  //   } else {
  //     alert("Geolocation is not supported by this browser.");
  //   }
  // }


  changeJobStatus() {
    // this.getLocation();
    // console.log("modalJobStatus",this.modalJob.status);
    // console.log("lat",this.lat);
    // console.log("lng",this.lng);
    // this.jobStatusForm.patchValue({
    //   status : this.modalJob.status == 'OPEN' ? 'IN_TRANSIT' : 'COMPLETED',
    //   lat : this.modalJob.status == 'OPEN' ? this.lat : '',
    //   lng : this.modalJob.status == 'OPEN' ? this.lng : ''
    // })

    this.jobStatusForm.patchValue({
      status: this.modalJob.status == 'OPEN' ? 'IN_TRANSIT' : 'COMPLETED'
    })

    this.loader.show();

    this.addJobService.updateJob(this.jobId, this.jobForm.value)
      .pipe(
        concatMap(editedJob => this.manageJobsService.changeJobStatus(this.jobId, this.jobStatusForm.value))
      ).subscribe(res => {
        this.jobStatusDialog = false;
        this.jobStatusForm.reset();
        this.jobForm.reset();
        this.jobId = null;
        this.modalJob;
        this.alertService.alertSuccess(['Spot status changed successfully']);
        this.getJobs(this.query, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy)
        // this.getJobs(this.query,"OPEN",null, null, null, null, null, null, null);
        this.getJobs(this.query,"IN_TRANSIT",this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
        this.getJobs(this.query,"COMPLETED",this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,null, this.createdBy);
        this.getJobs(this.query, "QUEUE",this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_DRIVER", this.createdBy);
        this.getJobs(this.query, "QUEUE",this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_SPOTTER", this.createdBy);
        this.loader.hide();
        // this.getJobs(this.query,"EXCEPTION");
        
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      });

  }

  filterJobsByStatus(event) {
    // this.fromDate = undefined;
    // this.toDate = undefined;
    this.filterByStatus = event.value;
    // if(this.filterByClient){
    // this.getJobs(this.query,this.filterByStatus,this.filterByClient,this.fromDate,this.toDate,this.selectedfleetId);
    // }else{
    //   this.getJobs(this.query,this.filterByStatus);
    // }

    if (this.jobs.length == 0) {
      this.getJobs(this.query, this.filterByStatus, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
    }
    else {
      this.updateCurrentPage(0);
    }
  }

  filterJobsByClient(event) {
    // this.fromDate = undefined;
    // this.toDate = undefined;
    this.filterByClient = event.value;
    // if(this.filterByStatus){
    // 
    // }else{
    //   this.getJobs(this.query,null,this.filterByClient);
    // }

    if (this.jobsOpen.length == 0) {
      this.getJobs(this.query, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {
      this.updateCurrentPage(0);
    }

    if (this.jobsIntransit.length == 0) {
      this.getJobs(this.query, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {
      this.updateCurrentPageIntransit(0);
    }

    if (this.jobsCompleted.length == 0) {
      this.getJobs(this.query, "COMPLETED", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {
      this.updateCurrentPageCompleted(0);
    }

    if (this.jobsDriverQueue.length == 0) {
      this.getJobs(this.query, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_DRIVER", this.createdBy);
    }
    else {
      this.updateCurrentPageDriverQueue(0);
    }

    if (this.jobsSpotterQueue.length == 0) {
      this.getJobs(this.query, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_SPOTTER", this.createdBy);
    }
    else {
      this.updateCurrentPageSpotterQueue(0);
    }
    // if (this.jobsException.length == 0) {
    //   this.getJobs(this.query, "EXCEPTION", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
    // }
    // else {
    //   this.updateCurrentPageException(0);
    // }

  }

  getClientList(query) {
    this.loader.show();
    this.manageClientsService.viewClients(query).subscribe(response => {
      this.clientList = response.list;
      this.loader.hide();
      if(this.isSupervisor)
      {
        this.filterByClient = this.clientList[0].clientId;
      }
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  exportCsv() {
    if (this.filterByStatus && this.filterByClient && this.fromDate && this.toDate) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}&pickupLocation.client.uuid=${this.filterByClient}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
    } else if (this.filterByStatus && this.filterByClient) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}&pickupLocation.client.uuid=${this.filterByClient}`, '_blank');
    } else if (this.filterByStatus && this.fromDate && this.toDate) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
    } else if (this.filterByClient && this.fromDate && this.toDate) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&pickupLocation.client.uuid=${this.filterByClient}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
    } else if (this.filterByStatus) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}`, '_blank');
    } else if (this.filterByClient) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&pickupLocation.client.uuid=${this.filterByClient}`, '_blank');
    } else if (this.fromDate && this.toDate) {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
    } else {
      window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/csv?isActive=true&access_token=${this.accessToken}`, '_blank');
    }
  }

  // exportExcel() {
  //   if (this.filterByStatus && this.filterByClient && this.fromDate && this.toDate) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}&pickupLocation.client.uuid=${this.filterByClient}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
  //   } else if (this.filterByStatus && this.filterByClient) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}&pickupLocation.client.uuid=${this.filterByClient}`, '_blank');
  //   } else if (this.filterByStatus && this.fromDate && this.toDate) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
  //   } else if (this.filterByClient && this.fromDate && this.toDate) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&pickupLocation.client.uuid=${this.filterByClient}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
  //   } else if (this.filterByStatus) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&status=${this.filterByStatus}`, '_blank');
  //   } else if (this.filterByClient) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&pickupLocation.client.uuid=${this.filterByClient}`, '_blank');
  //   } else if (this.fromDate && this.toDate) {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}&fromDate=${this.fromDate}&toDate=${this.toDate}`, '_blank');
  //   } else {
  //     window.open(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel?isActive=true&access_token=${this.accessToken}`, '_blank');
  //   }
  // }

  exportExcel() {
    const url = new URL(`${APP_CONSTANTS.BASE_API_URL}/v1/jobs/export/excel`);
    url.searchParams.append("isActive", "true");
    url.searchParams.append("access_token", this.accessToken);
  
    if (this.filterByStatus) {
      url.searchParams.append("status", this.filterByStatus);
    }
    if (this.filterByClient) {
      url.searchParams.append("pickupLocation.client.uuid", this.filterByClient);
    }
    if (this.fromDate) {
      url.searchParams.append("fromDate", this.fromDate);
    }
    if (this.toDate) {
      url.searchParams.append("toDate", this.toDate);
    }
    if(this.selectedfleetId) {
      url.searchParams.append("fleet.uuid", this.selectedfleetId);
    }
  
    window.open(url.toString(), '_blank');
  }
  
  jobCompletionTime(completionTime) {
    if (completionTime) {
      completionTime = parseInt(completionTime) //because moment js dont know to handle number in string format
      var hours = Math.floor(moment.duration(completionTime, 'seconds').asHours()).toLocaleString();
      var minutes = moment.duration(completionTime, 'seconds').minutes().toLocaleString();
      var seconds = moment.duration(completionTime, 'seconds').seconds().toLocaleString();
      hours = hours.length == 1 ? "0" + hours : hours;
      minutes = minutes.length == 1 ? "0" + minutes : minutes;
      seconds = seconds.length == 1 ? "0" + seconds : seconds;
      var format = hours + ":" + minutes + ":" + seconds;
      //Math.floor(moment.duration(seconds,'seconds').asHours()) + ':' + moment.duration(seconds,'seconds').minutes() + ':' + moment.duration(seconds,'seconds').seconds();
      return format;
    } else {
      return completionTime;
    }
  }


  getClientLocations(query, clientId) {
    this.clientId = clientId;
    this.loading = true;
    this.subscription.add(
      this.manageLocationsService.viewLocations(query, clientId).subscribe(response => {
        this.locationList = response.list;
        // this.totalRecords = response.totalElements;
        this.loading = false;
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
    )
  }



  getSpots(query, clientId, locationId) {
    this.loader.show();
    this.manageSpotsService.viewSpots(query, clientId, locationId)
      .pipe(
        map(spots => {
          let spotsArray = [];
          for (let spot of spots.list) {
            let obj = {
              ...spot,
              spotAndStatus: this.spotAndFleetStatus(spot)
            };
            spotsArray.push(obj);
          }
          return {
            list: spotsArray,
          }

        })
      ).subscribe(res => {
        this.loader.hide();
        this.dropSpots = res.list;
      }, (error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })
  }

  spotAndFleetStatus(spot) {
    if (spot.status == 'EMPTY' && spot?.fleet == null) {
      return `${spot.spotName} - Empty`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'FULL') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Full Trailer`
    } else if (spot.status == 'OCCUPIED' && spot?.fleet?.fleetStatus == 'EMPTY') {
      return `${spot.spotName} - ${spot?.fleet?.unitNumber} - Occupied Empty Trailer`
    } else if (spot.status == 'TO_BE_EMPTY') {
      return spot?.fleet == null ? `${spot.spotName} - Scheduled for Pick-Up` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Scheduled for Pick-Up`
    } else if (spot.status == 'TO_BE_OCCUPIED') {
      return spot?.fleet == null ? `${spot.spotName} - Location Reserved` : `${spot.spotName} - ${spot?.fleet?.unitNumber} - Location Reserved`
    } else {
      return `${spot.spotName}`
    }

  }



  filterJobsByDate() {
    if (this.toDate !== undefined) {
      if (this.jobsOpen.length == 0) {
        this.getJobs(this.spotsQuery, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
      }
      else {
        this.updateCurrentPage(0);
      }

      if (this.jobsIntransit.length == 0) {
        this.getJobs(this.spotsQuery, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
      }
      else {
        this.updateCurrentPageIntransit(0);
      }

      if (this.jobsCompleted.length == 0) {
        this.getJobs(this.spotsQuery, "COMPLETED", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
      }
      else {
        this.updateCurrentPageCompleted(0);
      }
      if (this.jobsDriverQueue.length == 0) {
        this.getJobs(this.spotsQuery, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, "BUCKET_DRIVER", this.createdBy);
      }
      else {
        this.updateCurrentPageDriverQueue(0);
      }
      if (this.jobsSpotterQueue.length == 0) {
        this.getJobs(this.spotsQuery, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, "BUCKET_SPOTTER", this.createdBy);
      }
      else {
        this.updateCurrentPageSpotterQueue(0);
      }
      // if (this.jobsException.length == 0) {
      //   this.getJobs(this.spotsQuery, "EXCEPTION", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
      // }
      // else {
      //   this.updateCurrentPageException(0);
      // }

    }

  }
  deleteJob(jobId) {
    this.deleteJobDialog = true;
    this.deleteJobId = jobId;
  }

  confirmDelete() {
    this.loader.show();
    this.addJobService.deleteJob(this.deleteJobId).subscribe(res => {
      this.deleteJobDialog = false;
      this.deleteJobId = undefined;
      this.alertService.alertSuccess([`Deleted Successfully`]);
      this.getJobs(this.query,"OPEN");
      this.getJobs(this.query,"IN_TRANSIT");
      this.getJobs(this.query,"COMPLETED");
      this.getJobs(this.spotsQuery, "QUEUE", null, null, null, null, null, null, null,"BUCKET_DRIVER");
      this.getJobs(this.spotsQuery, "QUEUE", null, null, null, null, null, null, null, "BUCKET_SPOTTER");
     
      // this.getJobs(this.query,"EXCEPTION");
      this.loader.hide();
    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
  }

  filterFleets(event) {
    this.i++;
    setTimeout(() => {  
      this.j++;
      if(this.i == this.j)
        {
          this.manageFleetsService.viewFleets(this.trailerQuery, undefined, event.query, true)
          .pipe(
            map(fleets => {
              let fleetsArray = [];
              for (let fleet of fleets.list) {
                let obj = {
                  ...fleet,
                  fleetAndHotTrailer: this.checkIfHotTrailer(fleet)
                };
                fleetsArray.push(obj);
              }
              return {
                list: fleetsArray
              }
    
            })
          ).subscribe(response => {
            this.filteredFleets = response.list;
            this.loader.hide();
          }, (error) => {
            this.loader.hide();
            this.errorService.handleError(error, true);
          });
        }   
      
  }, 1000);
  }

  checkIfHotTrailer(fleet) {
    if (fleet.isHotTrailer) {
      return `${fleet.unitNumber} - (Hot Trailer)`
    } else {
      return `${fleet.unitNumber}`
    }
  }


  clearFilter(event) {
    this.selectedfleetId = null;

    this.getJobs(this.spotsQuery, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    this.getJobs(this.spotsQuery, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    this.getJobs(this.spotsQuery, "COMPLETED", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    this.getJobs(this.spotsQuery, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_DRIVER", this.createdBy);
    this.getJobs(this.spotsQuery, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, "BUCKET_SPOTTER", this.createdBy);
   
    // this.getJobs(this.spotsQuery, "EXCEPTION", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
  }

  onFleetSelect(event) {
    // this.filterFleetId = event.fleetAndHotTrailer;
    this.selectedfleetId = event.fleetId;
    console.log("fleet",event);
    console.log(event.fleetId);
    if (this.jobsOpen.length == 0) {
      this.getJobs(this.spotsQuery, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {
      this.updateCurrentPage(0);
    }

    if (this.jobsIntransit.length == 0) {
      this.getJobs(this.spotsQuery, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {
      this.updateCurrentPageIntransit(0);
    }

    if (this.jobsCompleted.length == 0) {
      this.getJobs(this.spotsQuery, "COMPLETED", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {
      this.updateCurrentPageCompleted(0);
    }
    if (this.jobsDriverQueue.length == 0) {
      this.getJobs(this.spotsQuery, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, "BUCKET_DRIVER", this.createdBy);
    }
    else {
      this.updateCurrentPageDriverQueue(0);
    }
    if (this.jobsSpotterQueue.length == 0) {
      this.getJobs(this.spotsQuery, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, "BUCKET_SPOTTER", this.createdBy);
    }
    else {
      this.updateCurrentPageSpotterQueue(0);
    }


    // if (this.jobsException.length == 0) {
    //   this.getJobs(this.spotsQuery, "EXCEPTION", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
    // }
    // else {
    //   this.updateCurrentPageException(0);
    // }
  }

  onSelectLocation(event) {

    this.getSpots(this.spotsQuery, this.clientId, event.value);
  }

  viewBol(bols) {
    this.selectedBol = bols;
    this.bolDialog = true;
    this.selectedImagePath = this.selectedBol[0].imagePath;
  }

  exceptions(jobID) {
    this.exceptionDialog = true;
    this.exceptionJobId = jobID;
  }

  // downloadImage(url: string): void {


  //   console.log("hello");
  //   console.log(url);
  //   this.http.get(url,  {
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //       'Access-Control-Allow-Methods': 'GET',
  //       'Access-Control-Max-Age': '3000'
  //     }
  //     // These params can be passed based on your need
  //   })
  //     .subscribe((response: Blob) => {
  //       saveAs(response, 'downloaded-image.jpg');
  //     }, error => {
  //       console.error('Error downloading the image', error);
  //     });
  // }

  onPageChange(event: any) {
    this.selectedImagePath = this.selectedBol[event.page].imagePath;

  }

  sortCreationDate(event: any, op: any, status: any, bucket?: string) {
    op.hide();
    if (event.value != null) {
      this.sortCreatedDate = event.value.code;
    }
    else {
      this.sortCreatedDate = null;
    }

    this.getJobs(this.query, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, bucket, this.createdBy);
  }



  viewUsers(query: IpagedQuery, clientId?: any) {
    this.loader.show();
    this.subscription.add(
      this.manageUserService.viewUsers(query, clientId).
        pipe(
          map(res => {
            let users = [];
            for (let user of res.list) {
              let obj = {
                ...user,
                fullName: user.firstName + " " + user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users => {
          let usersList = [];
          users.map(user => {
            for (let role of user["roles"]) {
              
              if (role.roleName === "DRIVER" || role.roleName === "SPOTTER" || role.roleName === "SUPERVISOR") {
                usersList.push(user);
              }
            }
          })
          this.drivers = usersList;
          if (this.isDriver || this.isSpotter) {
            this.drivers = this.drivers.filter(user => user.userId === this.userId);
          }
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  viewAllUsers(query: IpagedQuery, clientId?: any) {
    this.loader.show();
    this.subscription.add(
      this.manageUserService.viewUsers(query, clientId, null, null, true).
        pipe(
          map(res => {
            let users = [];
            for (let user of res.list) {
              let obj = {
                ...user,
                fullName: user.firstName + " " + user.lastName
              };
              users.push(obj);
            }
            return users
          })
        ).subscribe(users => {

          let allUsersList = [];
          users.map(user => {
            allUsersList.push(user);
          })
          this.allUsers = allUsersList;
          this.loader.hide();
        }, (error) => {
          this.loader.hide();
          this.errorService.handleError(error, true);
        })
    )
  }

  sortByAssignedUser(event: any, op1: any, status: any) {
    op1.hide();
    if (event.value == null) {
      this.assignedTo = "";
      this.getJobs(this.query, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }
    else {

      console.log(this.assignedDriver);
      this.assignedTo = event.value.userId;
      this.getJobs(this.query, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes, null, this.createdBy);
    }

  }

  sortByCreatedUser(event: any, op2: any, status: any, bucket?:string)
  {
    op2.hide();
    if (event.value == null) {
      this.createdBy = "";
      this.getJobs(this.query, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,bucket,this.createdBy);
    }
    else {

      this.createdBy = event.value.userId;
      this.getJobs(this.query, status, this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,bucket,this.createdBy);
    }
  }

  selectException(exception:any, input:any = 0){
    if(input == 1){
      this.exceptionInputText = "";
    }
    this.selectedException = exception;
  }

  sendException(){
    this.loader.show();
    this.manageJobsService.addException(this.exceptionJobId, this.selectedException)
   .subscribe(res => {
      
      this.alertService.alertSuccess(['Exception added successfully']);
      this.getJobs(this.query, "EXCEPTION", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
      this.getJobs(this.query, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
      this.getJobs(this.query, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
      this.exceptionDialog = false;
      this.exceptionInputText = "";
      this.selectedException = "";
      this.loader.hide();

    }, (error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    });
  }

  cancelExceptionDialog(){
    this.exceptionInputText = "";
    this.selectedException = "";
    this.exceptionDialog = false;
  }

  searchInNotes(){
    this.k++;
    setTimeout(() => {
       
      this.l++;
      if(this.k == this.l)
      {

        if (this.jobsOpen.length == 0) {
          this.getJobs(this.query, "OPEN", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
        }
        else {
          this.updateCurrentPage(0);
        }
    
        if (this.jobsIntransit.length == 0) {
          this.getJobs(this.query, "IN_TRANSIT", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
        }
        else {
          this.updateCurrentPageIntransit(0);
        }
    
        if (this.jobsCompleted.length == 0) {
          this.getJobs(this.query, "COMPLETED", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes);
        }
        else {
          this.updateCurrentPageCompleted(0);
        }
    
        if (this.jobsDriverQueue.length == 0) {
          this.getJobs(this.query, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_DRIVER");
        }
        else {
          this.updateCurrentPageDriverQueue(0);
        }
    
        if (this.jobsSpotterQueue.length == 0) {
          this.getJobs(this.query, "QUEUE", this.filterByClient, this.fromDate, this.toDate, this.selectedfleetId, this.sortCreatedDate, this.assignedTo, this.searchNotes,"BUCKET_SPOTTER");
        }
        else {
          this.updateCurrentPageSpotterQueue(0);
        } 

      }
        
  }, 1000);
   
  }

  onRowReorderFirstTable(event: any) {
    console.log("event",event);
   
    let Obj = {
    bucket : 'BUCKET_DRIVER',
    clientId : this.filterByClient,
    dragIndex : event.dragIndex, 
    dropIndex : event.dropIndex
    };

     this.manageJobsService.changeQueueOrder(Obj).subscribe(res=>{
      this.alertService.alertSuccess(["Successfully changed the order of driver queue"]);
     this.loader.hide();
    },(error) => {
      this.loader.hide();
      this.errorService.handleError(error, true);
    })
    
  }

  onRowReorderSecondTable(event: any) {
    console.log("event",event);
   
    let Obj = {
      bucket : 'BUCKET_SPOTTER',
      clientId : this.filterByClient,
      dragIndex : event.dragIndex, 
      dropIndex : event.dropIndex
      };
  
       this.manageJobsService.changeQueueOrder(Obj).subscribe(res=>{
        this.alertService.alertSuccess(["Successfully changed the order of spotter queue"]);
       this.loader.hide();
      },(error) => {
        this.loader.hide();
        this.errorService.handleError(error, true);
      })

  }


}
